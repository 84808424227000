import React, { useRef, useState } from 'react';

import FormEntry from './FormEntry';
import Icon from './Icon';

import { commonSizes } from '../../utils';
import { t } from 'zedevs-locales';

export default function ProfileSelection({
  selection = [],
  searchCallback = () => {},
  addCallback = () => {},
  removeCallback = () => {},
  emptyText = '',
  direction = 'down',
  readOnly = false,
}) {
  const input = useRef(null);
  const [suggestions, setSuggestions] = useState([]);

  return (
    <div className="row">
      <div className="container">
        <div className="row">
          {!readOnly && (
            <FormEntry
              id="search"
              {...commonSizes.simple}
              label={t('commons.search')}
            >
              <div
                className={`drop${direction} ${
                  suggestions.length ? 'show' : ''
                }`}
              >
                <input
                  type="text"
                  id="search"
                  className="form-control"
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSuggestions(searchCallback(e.target.value));
                  }}
                  ref={input}
                />
                <div
                  id="suggestions"
                  className={`dropdown-menu ${
                    suggestions.length ? 'show' : ''
                  }`}
                >
                  {suggestions.map(({ key, label }) => (
                    <a
                      key={key}
                      className="dropdown-item"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        input.current.value = '';
                        input.current.focus();
                        setSuggestions([]);
                        addCallback(key);
                      }}
                    >
                      {label}
                    </a>
                  ))}
                </div>
              </div>
            </FormEntry>
          )}
          <div className="col-12">
            {selection.length ? (
              selection.map(({ key, label }) => {
                return (
                  <div key={key} className="badge badge-dark m-1">
                    {label}
                    {!readOnly && (
                      <a
                        href="#"
                        className="btn btn-sm btn-secondary ml-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          removeCallback(key);
                        }}
                      >
                        <Icon name="close" />
                      </a>
                    )}
                  </div>
                );
              })
            ) : (
              <div>{emptyText}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
