import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';

import { branch as actionsWrapper } from '../high-order/actions';

import PlanList from '../display/plan/List';

export const myplans = dataWrapper(
  {
    plans: ['data', 'plans'],
  },
  actionsWrapper(['profile', 'toast'], ({ plans }) => (
    <div className="container">
      <PlanList plans={plans} />
    </div>
  ))
);

myplans.header = true;
myplans.footer = true;
