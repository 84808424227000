import React from 'react';

import { t } from 'zedevs-locales';
import { branch as actionsWrapper } from '../../high-order/actions';

import Icon from '../../elements/Icon';

import { mailToPrinter, buttonGroupPrinter } from '../../elements/printers';
import { Table, Column } from '../../elements/table';

function MemberList({ role, members = [], readOnly, editCallback, actions }) {
  return (
    <div className="row">
      <h2 className="col-12">{t(`roles.${role}`)}</h2>
      <Table items={members}>
        <Column itemKey="firstname" width="15" />
        <Column itemKey="lastname" width="20" />
        <Column itemKey="email" width="35" printer={mailToPrinter()} />
        <Column itemKey="phone" width="15" />
        {!readOnly && (
          <Column
            title=""
            width="20"
            align="center"
            printer={buttonGroupPrinter([
              {
                label: <Icon name="resend" />,
                callback({ uuid }) {
                  actions.profile.reinvite(
                    { uuid },
                    actions.toast.wrapper({
                      error: 'colleague-invite-error',
                      success: 'colleague-invite-success',
                    })
                  );
                },
                display({ status }) {
                  return status === 'pending' && role !== 'locked';
                },
              },
              {
                label: <Icon name="edit" />,
                callback: editCallback,
              },
              {
                label: <Icon name="userBan" />,
                callback({ uuid, organization }) {
                  actions.organization.grant(
                    { uuid, organization, role: 'locked' },
                    actions.toast.wrapper({
                      error: 'colleague-revoke-error',
                      success: 'colleague-revoke-success',
                      callback: () => actions.navigation.setMetadata(),
                    })
                  );
                },
                color: 'outline-danger',
                display: role !== 'locked',
              },
            ])}
          />
        )}
      </Table>
    </div>
  );
}

export default actionsWrapper(
  ['organization', 'profile', 'navigation', 'toast'],
  MemberList
);
