import Router from 'baobab-router';

import state from './state';

const definitions = {
  defaultRoute: '/',
  readOnly: [['logged']],
  routes: [
    {
      path: '/guest/:uuid',
      state: {
        navigation: {
          page: {
            section: 'intervention',
            display: 'guest',
            uuid: ':uuid',
          },
        },
      },
    },
    {
      path: '/register/:uuid',
      state: {
        logged: false,
        navigation: {
          page: { section: 'profile', display: 'register', uuid: ':uuid' },
        },
      },
    },
    {
      path: '/reset-password/:token',
      state: {
        logged: false,
        navigation: {
          page: { section: 'password', display: 'reset', uuid: ':token' },
        },
      },
    },
    {
      path: '/reset-password',
      state: {
        logged: false,
        navigation: {
          page: { section: 'password', display: 'forgot' },
        },
      },
    },
    {
      path: '/login',
      state: {
        logged: false,
      },
    },
    {
      path: '/',
      state: {
        logged: true,
        navigation: { page: { section: null } },
      },
      routes: [
        {
          path: '/my',
          state: {
            navigation: { page: { section: 'my' } },
          },
          routes: [
            {
              path: '/profile',
              state: { navigation: { page: { display: 'profile' } } },
            },
            {
              path: '/skills',
              state: { navigation: { page: { display: 'skills' } } },
            },
            {
              path: '/organization',
              state: { navigation: { page: { display: 'organization' } } },
            },
            {
              path: '/colleagues',
              state: { navigation: { page: { display: 'colleagues' } } },
            },
            {
              path: '/assists',
              state: { navigation: { page: { display: 'assists' } } },
            },
            {
              path: '/responses',
              state: { navigation: { page: { display: 'responses' } } },
            },
            {
              path: '/interventions',
              query: {
                view: { match: ':view' },
                date: { match: ':date', cast: 'number' },
              },
              state: {
                navigation: {
                  page: {
                    display: 'interventions',
                    metadata: { view: ':view', date: ':date' },
                  },
                },
              },
            },
            {
              path: '/availabilities',
              query: {
                view: { match: ':view' },
                date: { match: ':date', cast: 'number' },
              },
              state: {
                navigation: {
                  page: {
                    display: 'availabilities',
                    metadata: { view: ':view', date: ':date' },
                  },
                },
              },
            },
            {
              path: '/plans',
              state: { navigation: { page: { display: 'plans' } } },
            },
          ],
        },
        {
          path: '/assists',
          state: {
            navigation: { page: { section: 'assist', display: 'list' } },
          },
          routes: [
            {
              path: '/create',
              state: { navigation: { page: { display: 'create' } } },
            },
            {
              path: '/:uuid',
              state: {
                navigation: { page: { display: 'view', uuid: ':uuid' } },
              },
              routes: [
                {
                  path: '/edit',
                  state: {
                    navigation: { page: { display: 'edit' } },
                  },
                },
              ],
            },
          ],
        },
        {
          path: '/interventions',
          state: {
            navigation: { page: { section: 'intervention' } },
          },
          routes: [
            {
              path: '/:uuid',
              state: {
                navigation: { page: { display: 'view', uuid: ':uuid' } },
              },
            },
          ],
        },
        {
          path: '/members',
          state: {
            navigation: { page: { section: 'member', display: 'search' } },
          },
        },
        {
          path: '/plans/:uuid',
          state: {
            navigation: {
              page: { section: 'plan', display: 'view', uuid: ':uuid' },
            },
          },
        },
        {
          path: '/admin',
          state: {
            navigation: {
              page: { section: 'admin', display: 'organizations' },
            },
          },
          routes: [
            {
              path: '/skills',
              state: { navigation: { page: { display: 'skills' } } },
            },
            {
              path: '/member/:uuid',
              state: {
                navigation: {
                  page: { display: 'member', uuid: ':uuid' },
                },
              },
            },
            {
              path: '/plan/:uuid',
              state: {
                navigation: {
                  page: {
                    display: 'planedit',
                    uuid: ':uuid',
                    metadata: { mode: 'update' },
                  },
                },
              },
            },
            {
              path: '/:uuid',
              state: {
                navigation: {
                  page: { display: 'organization', uuid: ':uuid' },
                },
              },
              routes: [
                {
                  path: '/plan',
                  state: {
                    navigation: {
                      page: {
                        display: 'plancreate',
                        metadata: { mode: 'create' },
                      },
                    },
                  },
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export default () => new Router(state, definitions);
