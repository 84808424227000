import React, { useState } from 'react';
import { types as typology } from '../utils';
import { transform, get } from 'lodash';
import { LocaleDate } from 'zedevs-locales';

import LoadingButton from '../components/elements/LoadingButton';

function getData(inputs, defaultValue) {
  return transform(
    inputs,
    (values, input, key) => {
      const value = input.value || defaultValue;

      if (input.type === 'number') {
        values[key] = +value;
      } else if (input.type === 'checkbox') {
        values[key] = input.checked;
      } else if (input.type === 'date') {
        values[key] = value ? new LocaleDate(value).getTime() : undefined;
      } else {
        values[key] = value === '' ? undefined : value;
      }

      return values;
    },
    {}
  );
}

export default (actions) => ({
  action = () => {},
  callback = () => {},
  defaultValue,
  types,
  error,
  success,
  button = {},
}) => {
  const [form, setForm] = useState();
  const [submit, setSubmit] = useState();
  const inputs = {};

  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    if (submit && submit.loading) return undefined;

    const data = getData(inputs, defaultValue);

    if (types && !typology.check(types, data)) {
      const scan = typology.scan(types, data);

      const input = get(inputs, scan.path.join('.'));
      if (input && input.focus && typeof input.focus === 'function') {
        input.focus();
      }

      return actions.toast.invalidData(scan);
    }

    submit.startLoading();
    return action(
      data,
      actions.toast.wrapper({
        error,
        success,
        callback(err, ...args) {
          if (!err && form) form.reset();
          submit.stopLoading();
          callback(err, ...args);
        },
      })
    );
  };

  return {
    getData() {
      return getData(inputs, defaultValue);
    },
    onSubmit,
    addInputRef(input) {
      if (!input) return;
      const id = input.id || input.name;
      if (id === undefined) return;
      inputs[id] = input;
    },
    addFormRef: setForm,
    submit: (
      <LoadingButton
        {...button}
        ref={(element) => setSubmit(element)}
        onClick={button.useClick ? onSubmit : undefined}
      />
    ),
  };
};
