import React, { useRef } from 'react';
import { t } from 'zedevs-locales';
import { branch as actionsWrapper } from '../high-order/actions';

import { branch as dataWrapper } from 'baobab-react/higher-order';

import Icon from '../elements/Icon';

import FormEntry from '../elements/FormEntry';
import { commonSizes } from '../../utils';
import { find } from 'lodash';

function ResponseCreate({ user, assist, plan, actions, hooks }) {
  const priceRef = useRef();
  const { addInputRef, submit } = hooks.useForm({
    action(data, callback) {
      actions.assist.respond({ assist: assist.uuid, data }, callback);
    },
    callback(err) {
      if (!err) {
        actions.navigation.close();
        actions.navigation.reload();
      }
    },
    error: 'response-create-error',
    success: 'response-create-success',
    button: {
      useClick: true,
    },
  });

  const provider = find(
    (plan || {}).providers || [],
    (p) => p.provider === user.organization
  );
  const priceFromPlan = (e) => {
    e.preventDefault();
    e.stopPropagation();
    priceRef.current.value = e.target.value * provider.purchaseRate;
  };

  return (
    <div className="response">
      <h1 className="row">
        <span className="col-10">{t('titles.new.response')}</span>
        <span className="title-icon col-2">
          <Icon name="response" />
        </span>
      </h1>
      <div className="row scrollable">
        <div className="container">
          <form className="row">
            <h2 className="col-12">{t('response.details')}</h2>
            <FormEntry id="description">
              <textarea
                type="text"
                className="form-control"
                id="description"
                ref={addInputRef}
              />
            </FormEntry>
            <FormEntry id="prerequisites">
              <textarea
                type="text"
                className="form-control"
                id="prerequisites"
                ref={addInputRef}
              />
            </FormEntry>
            <FormEntry id="duration" {...commonSizes.simple}>
              <div className="input-group">
                <input
                  type="number"
                  min="1"
                  defaultValue="1"
                  className="form-control"
                  id="duration"
                  ref={addInputRef}
                  onChange={assist.plan ? priceFromPlan : undefined}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    {t('commons.units.hour', 2)}
                  </div>
                </div>
              </div>
            </FormEntry>
            <FormEntry
              id="price"
              help={assist.plan ? undefined : t('fields.price.help')}
              {...commonSizes.simple}
            >
              <div className="input-group">
                <input
                  type="number"
                  min="1"
                  defaultValue={assist.plan ? provider.purchaseRate : 150}
                  className="form-control"
                  id="price"
                  ref={assist.plan ? priceRef : addInputRef}
                  readOnly={!!assist.plan}
                />
                <div className="input-group-append">
                  <div className="input-group-text">€ HT</div>
                </div>
              </div>
            </FormEntry>
          </form>
        </div>
      </div>
      <div className="row">
        <div className="form-group submit col-12">
          {submit}
          <button
            className="btn btn-outline-dark"
            type="reset"
            onClick={() => actions.navigation.close()}
          >
            {t('commons.cancel')}
          </button>
        </div>
      </div>
    </div>
  );
}

export const responsecreate = dataWrapper(
  {
    user: ['data', 'user'],
    assist: ['data', 'assist'],
    plan: ['data', 'plan'],
  },
  actionsWrapper(['assist', 'navigation', 'toast'], ResponseCreate)
);

responsecreate.header = true;
responsecreate.footer = true;
