import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../high-order/actions';

import ProfileForm from '../display/profile/Form';

export const myprofile = dataWrapper(
  {
    member: ['data', 'user'],
  },
  actionsWrapper(['profile', 'toast'], (props) => (
    <div className="profile">
      <ProfileForm {...props} title={t('titles.my.profile')} />
    </div>
  ))
);

myprofile.header = true;
myprofile.footer = true;
