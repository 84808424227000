import React, { Component, forwardRef } from 'react';

import { branch as actionsWrapper } from '../high-order/actions';

function preventSubmit(e) {
  if (e.keyCode === 13) {
    e.preventDefault();
    e.stopPropagation();
  }
}

class Autocompleted extends Component {
  constructor() {
    super();
    this.state = {
      autocompletions: [],
    };
    this.autocomplete = this.autocomplete.bind(this);
  }

  autocomplete(e) {
    const { actions, onEnter } = this.props;
    const value = e.target.value;

    if (e.keyCode === 13 && onEnter) {
      e.preventDefault();
      e.stopPropagation();
      e.target.value = '';
      return onEnter(value);
    }

    if (value.length < 2) {
      this.setState({ autocompletions: [] });
    } else {
      actions.skills.autocomplete({ name: value }, (err, res) => {
        this.setState({
          autocompletions: res
            .map((skill) => skill.name)
            .filter((skill) => skill !== value),
        });
      });
    }
    return undefined;
  }

  render() {
    const { autocompletions = [] } = this.state;
    const { forwardedRef, onEnter, ...props } = this.props;
    return (
      <div className="input-group">
        <input
          type="text"
          className="form-control"
          list="tag-autocomplete"
          onKeyUp={this.autocomplete}
          onKeyDown={onEnter ? preventSubmit : undefined}
          onKeyPress={onEnter ? preventSubmit : undefined}
          ref={forwardedRef}
          {...props}
        />
        <datalist id="tag-autocomplete">
          {autocompletions.map((autocompletion) => (
            <option key={autocompletion} value={autocompletion} />
          ))}
        </datalist>
      </div>
    );
  }
}

export default actionsWrapper(
  ['skills'],
  forwardRef(function ForwaredAutocompleted(props, ref) {
    return <Autocompleted {...props} forwardedRef={ref} />;
  }),
  true
);
