import React, { Children, cloneElement } from 'react';
import { t } from 'zedevs-locales';

import { Column } from './Column';

export function Table({ items = [], className, header = true, children }) {
  const columns = Children.toArray(children).filter(
    (child) => child.type.name === Column.name
  );

  const subHeader = !!columns.reduce(
    (count, { props }) => count + Children.toArray(props.children).length,
    0
  );

  return (
    <table className={className || 'table col-12'}>
      {header && (
        <thead>
          <tr>
            {columns.map(({ props }, i) => (
              <th
                className={props.width && `w-${props.width}`}
                key={`header-${i}`}
              >
                {props.title !== undefined
                  ? props.title
                  : t(['fields', props.itemKey, 'input-label'])}
              </th>
            ))}
          </tr>
        </thead>
      )}
      {subHeader && (
        <tbody>
          <tr>
            {columns.map(({ props }, i) => (
              <td key={`context-${i}`}>{props.children}</td>
            ))}
          </tr>
        </tbody>
      )}
      <tbody>
        {items.map((item, i) => {
          return (
            <tr key={`item-${i}`}>
              {columns.map((Col) => cloneElement(Col, { item }))}
            </tr>
          );
        })}
        {!items.length && (
          <tr>
            <td className="text-center" colSpan={columns.length}>
              {t('commons.no-result')}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
