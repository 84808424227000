import { transform, each } from 'lodash';
import { parallel } from 'async';

import logger from '../utils/logger';

const flags = {};

const dependencies = {
  admin: {
    organizations: [{ type: 'organization', action: 'list' }],
    organization: [
      { type: 'organization', action: 'get', uuid: true },
      { type: 'organization', action: 'members', uuid: true },
      { type: 'organization', action: 'plans', uuid: true },
    ],
    member: [
      { type: 'profile', action: 'get', uuid: true },
      { type: 'profile', action: 'getSkills', uuid: true },
    ],
    planedit: [
      { type: 'plan', action: 'get', uuid: true },
      { type: 'plan', action: 'getCandidates', uuid: true },
    ],
    skills: [{ type: 'skills', action: 'list' }],
  },
  my: {
    skills: [{ type: 'profile', action: 'getSkills' }],
    organization: [{ type: 'organization', action: 'get', uuid: true }],
    colleagues: [{ type: 'organization', action: 'members', uuid: true }],
    assists: [{ type: 'profile', action: 'getAssists' }],
    responses: [{ type: 'profile', action: 'getResponses' }],
    interventions: [{ type: 'profile', action: 'getInterventions' }],
    availabilities: [
      { type: 'profile', action: 'getAvailabilities' },
      { type: 'profile', action: 'getInterventions' },
    ],
    plans: [{ type: 'profile', action: 'getPlans' }],
  },
  profile: {
    register: [
      {
        type: 'profile',
        action: 'getRegistration',
        uuid: true,
        unlogged: true,
      },
    ],
  },
  password: {
    forgot: [],
    reset: [{ type: 'profile', action: 'checkPasswordToken', uuid: true }],
  },
  member: {
    search: [{ type: 'profile', action: 'search' }],
  },
  assist: {
    list: [{ type: 'assist', action: 'list' }],
    view: [
      { type: 'assist', action: 'get', uuid: true },
      { type: 'assist', action: 'getPlan', uuid: true, allowFaillure: true },
      { type: 'assist', action: 'getGuests', uuid: true, allowFaillure: true },
      {
        type: 'assist',
        action: 'getResponses',
        uuid: true,
        allowFaillure: true,
      },
      {
        type: 'assist',
        action: 'getIntervention',
        uuid: true,
        allowFaillure: true,
      },
    ],
    edit: [
      { type: 'assist', action: 'get', uuid: true },
      { type: 'profile', action: 'getPlans' },
    ],
    create: [{ type: 'profile', action: 'getPlans' }],
  },
  response: {
    view: [{ type: 'response', action: 'get', uuid: true }],
  },
  intervention: {
    view: [{ type: 'intervention', action: 'load', uuid: true }],
    guest: [
      {
        type: 'guest',
        action: 'load',
        uuid: true,
        allowFaillure: true,
        unlogged: true,
      },
    ],
  },
  skill: {
    create: [{ type: 'skills', action: 'get', uuid: true }],
  },
  guest: {
    delete: [{ type: 'guest', action: 'get', uuid: true }],
  },
  plan: {
    view: [{ type: 'plan', action: 'get', uuid: true }],
  },
  provider: {
    create: [{ type: 'organization', action: 'list' }],
    update: [
      { type: 'provider', action: 'get', uuid: true },
      { type: 'provider', action: 'getCandidates', uuid: true },
      { type: 'organization', action: 'list' },
    ],
  },
};

const dataCleaning = {
  admin: {
    organization: ['data.organization', 'data.members'],
    member: ['data.member', 'data.skills'],
  },
  my: {
    assists: ['data.assists'],
  },
  assist: {
    create: ['data.assist'],
    view: ['data.assist'],
    edit: ['data.assist'],
  },
  provider: {
    create: ['data.provider'],
  },
};

function callback({ actions, cursors, state }, done) {
  const { section, display, uuid } = (
    cursors.view || state.select('navigation', 'page')
  ).get();
  const logged = (cursors.logged || state.select('logged')).get();
  const tasks = [];

  // remove tooltip
  state.set(['navigation', 'tooltip'], {});

  ((dataCleaning[section] || {})[display] || []).forEach((path) =>
    state.set(path.split('.'), undefined)
  );

  const loading = state.select(['navigation', 'loading']);

  const deps = ((dependencies[section] || {})[display || 'none'] || []).filter(
    ({ unlogged }) => logged || unlogged
  );

  if (deps.length) {
    deps.forEach((dep) =>
      tasks.push((cb) => {
        loading.apply((n) => n + 1);
        try {
          actions[dep.type][dep.action](
            {
              uuid: dep.uuid ? uuid : undefined,
            },
            (err, data) => {
              loading.apply((n) => n - 1);
              cb(dep.allowFaillure ? null : err, data);
            }
          );
        } catch (err) {
          logger.error(`BINDING[${dep.type}][${dep.action}]`, err);
        }
      })
    );
  } else {
    tasks.push((cb) => setTimeout(cb, 0));
  }

  parallel(tasks, (err, data) => {
    if (err && err.xhr) {
      if (err.xhr.status === 401) return actions.session.logout();
      if (err.xhr.status === 403) return actions.navigation.open();
    }
    return done(err, data);
  });
}

const definitions = [
  {
    name: 'page-data-loader',
    paths: {
      view: ['navigation', 'page'],
      logged: ['logged'],
    },
    callback,
  },
  {
    name: 'modal-data-loader',
    paths: {
      view: ['navigation', 'modal'],
      logged: ['logged'],
    },
    callback,
  },
];

export default function bindings(state, actions) {
  definitions.forEach((binding) => {
    flags[binding.name] = false;

    const cursors = transform(
      binding.paths,
      (keep, path, name) => {
        keep[name] = state.select(path);
        return keep;
      },
      {}
    );

    each(cursors, (cursor) =>
      cursor.on('update', () => {
        // prevent binding from being call twice the same frame.
        if (!flags[binding.name]) {
          flags[binding.name] = cursor.path;

          logger.debug(`BINDING[${binding.name}]`, {
            path: cursor.path.join('.'),
            handler: flags[binding.name].join('.'),
          });

          binding.callback(
            { cursors, actions, state },
            () => (flags[binding.name] = false)
          );
        }
      })
    );
  });
  return state;
}
