import React from 'react';

import Icon from '../elements/Icon';

import { t } from 'zedevs-locales';

import List from '../display/response/List';

export function myresponses() {
  return (
    <div className="assist">
      <h1 className="row">
        <span className="col-10">{t('titles.my.responses')}</span>
        <span className="title-icon col-2">
          <Icon name="response" />
        </span>
      </h1>
      <List
        navigation={{
          section: 'assist',
          display: 'view',
          uuid: ({ assist }) => assist,
          container: 'page',
        }}
      />
    </div>
  );
}

myresponses.header = true;
myresponses.footer = true;
