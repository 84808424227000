import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/free-regular-svg-icons';

export default function Footer() {
  return (
    <div className="footer">
      <div className="copyright">
        <span>WeKey - 2020 </span>
        <FontAwesomeIcon icon={faCopyright} />
      </div>
      <div className="by">
        <a href="#">ZeDevs</a>
        <span> by </span>
        <a href="#">WeKey</a>
      </div>
      <div className="language"></div>
    </div>
  );
}
