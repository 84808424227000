import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../high-order/actions';

import Icon from '../elements/Icon';
import Details from '../display/skills/Details';

function SkillCreate({ skill }) {
  return (
    <div className="skill">
      <h1 className="row">
        <span className="col-10">
          {t(['titles', skill ? 'view' : 'new', 'skill'])}
        </span>
        <span className="title-icon col-2">
          <Icon name="skill" />
        </span>
      </h1>
      <Details skill={skill} />
    </div>
  );
}

export const skillcreate = dataWrapper(
  {
    skill: ['data', 'skill'],
  },
  actionsWrapper(['skills', 'navigation', 'toast'], SkillCreate)
);
