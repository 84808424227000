import React from 'react';

import Navigation from '../Navigation';

export const navigatePrinter = ({
  section,
  display,
  uuidKey = 'uuid',
  container = 'page',
  metadata = {},
}) => {
  return function NavigatePrinter({ item, itemKey }) {
    return (
      <Navigation
        {...{ section, display, container, metadata, uuid: item[uuidKey] }}
      >
        {item[itemKey]}
      </Navigation>
    );
  };
};
