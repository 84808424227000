import React from 'react';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../high-order/actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import Logo from '../elements/Logo';
import Navigation from '../elements/Navigation';

export const passwordforgot = actionsWrapper(
  ['profile'],
  ({ actions, hooks }) => {
    const { onSubmit, addInputRef, submit } = hooks.useForm({
      action: actions.profile.forgotPassword,
      types: {
        email: 'string',
      },
      error: 'forgot-password-failed',
      success: 'forgot-password-success',
    });

    return (
      <div className="login">
        <div className="mask">
          <div className="center">
            <Navigation className="return">
              <FontAwesomeIcon icon={faChevronLeft} className="text-light" />
            </Navigation>
            <Logo />
            <form className="form" autoComplete="off" onSubmit={onSubmit}>
              <h4 className="title">{t('login.forgotten-password')}</h4>
              <div className="form-group">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </div>
                  </div>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    placeholder={t('fields.email.input-label')}
                    ref={addInputRef}
                  />
                </div>
              </div>
              <div className="form-group submit">{submit}</div>
            </form>
          </div>
        </div>
      </div>
    );
  }
);
passwordforgot.header = false;
passwordforgot.footer = true;
