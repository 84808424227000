import React from 'react';

import { LocaleDate } from 'zedevs-locales';

export const datePrinter = ({ format = 'LLLL' } = {}) => {
  return function DatePrinter({ item, itemKey }) {
    if (!item[itemKey]) return null;
    return <span>{new LocaleDate(item[itemKey]).format(format)}</span>;
  };
};

export const dateFromNowPrinter = () => {
  return function DateFromNomPrinter({ item, itemKey }) {
    return (
      <span>{item[itemKey] ? new LocaleDate(item[itemKey]).from() : null}</span>
    );
  };
};

export const durationPrinter = () => {
  return function DurationPrinter({ item, itemKey }) {
    return (
      <span>{item[itemKey] ? LocaleDate.duration(item[itemKey]) : ''}</span>
    );
  };
};
