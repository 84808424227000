import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t } from 'zedevs-locales';

import Icon from '../../elements/Icon';

import {
  numberPrinter,
  buttonGroupPrinter,
  dateFromNowPrinter,
  durationPrinter,
} from '../../elements/printers';
import { Table, Column } from '../../elements/table';

function ResponseList({
  responses = [],
  navigation = {
    section: 'response',
    display: 'view',
    uuid: ({ uuid }) => uuid,
    container: 'modal',
  },
}) {
  return (
    <div className="col-12">
      <div className="row">
        <h2 className="col-12">{t('titles.list.responses')}</h2>
        <Table items={responses}>
          <Column
            title={t('fields.date.input-label')}
            itemKey="created"
            printer={dateFromNowPrinter()}
          />
          <Column itemKey="description" />
          <Column
            itemKey="duration"
            title={t('fields.estimatedDuration.input-label')}
            printer={durationPrinter({ unit: 'hour' })}
          />
          <Column itemKey="price" printer={numberPrinter({ unit: '€ HT' })} />
          <Column
            title=""
            printer={buttonGroupPrinter([
              {
                label: (
                  <span>
                    {t('commons.display')}
                    &nbsp;
                    <Icon name="display" />
                  </span>
                ),
                color: 'primary',
                navigation,
              },
            ])}
            align="center"
          />
        </Table>
      </div>
    </div>
  );
}

export default dataWrapper({ responses: ['data', 'responses'] }, ResponseList);
