import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t } from 'zedevs-locales';

import Icon from '../elements/Icon';

import {
  booleanPrinter,
  arrayPrinter,
  buttonGroupPrinter,
} from '../elements/printers';
import { Table, Column } from '../elements/table';
import Navigation from '../elements/Navigation';
import SkillSearch from '../display/skills/Search';

function AdminSkills({ skills = [] }) {
  return (
    <div className="organization">
      <h1 className="row">
        <span className="col-10">{t('titles.list.skills')}</span>
        <span className="title-icon col-2">
          <Icon name="skill" />
        </span>
      </h1>
      <SkillSearch />
      <div>
        <Navigation
          className="btn btn-primary"
          section="skill"
          display="create"
          container="modal"
        >
          {t('commons.add')} <Icon name="add" />
        </Navigation>
      </div>
      <Table items={skills}>
        <Column itemKey="name" />
        <Column itemKey="categories" printer={arrayPrinter()} />
        <Column itemKey="approved" printer={booleanPrinter()} align="center" />
        <Column
          title=""
          printer={buttonGroupPrinter([
            {
              label: <Icon name="edit" />,
              navigation: {
                section: 'skill',
                display: 'create',
                container: 'modal',
                uuid: ({ uuid }) => uuid,
              },
            },
          ])}
          align="center"
        />
      </Table>
    </div>
  );
}

export const adminskills = dataWrapper(
  { skills: ['data', 'skills'] },
  AdminSkills
);

adminskills.header = true;
adminskills.footer = true;
