import React from 'react';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../../high-order/actions';

import { buttonGroupPrinter } from '../../elements/printers';
import { Table, Column } from '../../elements/table';

import Icon from '../../elements/Icon';
import actions from '../../../core/actions';

function Guests({ assist = {}, guests = [], hooks }) {
  const { onSubmit, addInputRef, addFormRef, submit } = hooks.useForm({
    action: ({ email }, callback) =>
      actions.assist.addGuest({ assist: assist.uuid, email }, callback),
    error: 'assist-guest-add-error',
    success: 'assist-guest-add-success',
    callback() {
      actions.navigation.reload();
    },
    button: {
      label: 'commons.add',
      icon: <Icon name="add" />,
    },
  });
  return (
    <form onSubmit={onSubmit} ref={addFormRef} className="col-12">
      <div className="row">
        <h2 className="col-12">{t('titles.list.guests')}</h2>
        <Table items={guests}>
          <Column itemKey="email">
            {assist.step < 2 && (
              <div className="input-group">
                <input
                  type="text"
                  name="email"
                  className="form-control"
                  ref={addInputRef}
                />
              </div>
            )}
          </Column>
          <Column
            title=""
            printer={buttonGroupPrinter([
              {
                label: (
                  <span>
                    {t('commons.delete')}
                    &nbsp;
                    <Icon name="remove" />
                  </span>
                ),
                color: 'outline-danger',
                navigation: {
                  container: 'modal',
                  section: 'guest',
                  display: 'delete',
                  uuid: ({ uuid }) => uuid,
                },
              },
            ])}
            align="center"
          >
            {assist.step < 2 && (
              <div className="btn-group input-group" role="group">
                {submit}
              </div>
            )}
          </Column>
        </Table>
      </div>
    </form>
  );
}

export default actionsWrapper(['navigation', 'assist'], Guests);
