import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { LocaleDate, t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../high-order/actions';

import Icon from '../elements/Icon';

import FormEntry from '../elements/FormEntry';
import Tags from '../elements/Tags';
import { commonSizes } from '../../utils';

function OptGroup(title, plans) {
  if (!plans.length) return undefined;

  return (
    <optgroup label={t(title)} key={title}>
      {plans.map((p) => (
        <option value={p.uuid} key={p.uuid}>
          {p.name}
        </option>
      ))}
    </optgroup>
  );
}

const assistform = dataWrapper(
  {
    plans: ['data', 'plans'],
    current: ['data', 'assist'],
  },
  actionsWrapper(
    ['assist', 'navigation'],
    ({ actions, hooks, plans = [], current }) => {
      const mode = current ? 'update' : 'create';

      const { onSubmit, addInputRef, submit } = hooks.useForm({
        action: (data, callback) =>
          actions.assist[mode]({ uuid: (current || {}).uuid, data }, callback),
        types: {
          title: 'string',
          description: 'string',
          keywords: '?string[]',
          plan: '?string',
        },
        error: `assist-${mode}-error`,
        success: `assist-${mode}-success`,
        callback: (err, { uuid } = {}) =>
          actions.navigation.open({ section: 'assist', display: 'view', uuid }),
        button: {
          label: 'commons.save',
        },
      });

      const internalPlans = [],
        hybridPlans = [],
        externalPlans = [],
        availlablePlans = plans.filter(
          ({ isApplicant, expiresAt, credit }) =>
            isApplicant &&
            (!expiresAt || new LocaleDate(expiresAt).isAfter()) &&
            (!credit.amount || credit.amount - credit.used > 0)
        );

      availlablePlans.forEach((p) => {
        if (!p.isProvider) externalPlans.push(p);
        else if (p.providers.length > 1) hybridPlans.push(p);
        else internalPlans.push(p);
      });

      return (
        <form className="assist" onSubmit={onSubmit}>
          <h1 className="row">
            <span className="col-10">
              {t(['titles', current ? 'view' : 'new', 'assist'])}
            </span>
            <span className="title-icon col-2">
              <Icon name="assist" />
            </span>
          </h1>
          <div className="row">
            <FormEntry id="title" {...commonSizes.simple}>
              <input
                type="text"
                className="form-control"
                id="title"
                ref={addInputRef}
                defaultValue={(current || {}).title}
              />
            </FormEntry>
            <FormEntry id="description">
              <textarea
                type="text"
                className="form-control"
                id="description"
                ref={addInputRef}
                defaultValue={(current || {}).description}
              />
            </FormEntry>
            <FormEntry id="keywords">
              <Tags
                id="keywords"
                ref={addInputRef}
                defaultValue={(current || {}).keywords}
              />
            </FormEntry>
            <FormEntry id="plan" {...commonSizes.simple}>
              <select id="plan" ref={addInputRef} className="form-control">
                <option key="none" value="">
                  {t('plans.none')}
                </option>
                {OptGroup('plans.internals', internalPlans)}
                {OptGroup('plans.hybrids', hybridPlans)}
                {OptGroup('plans.externals', externalPlans)}
              </select>
            </FormEntry>
            <div className="form-group submit col-12">{submit}</div>
          </div>
        </form>
      );
    }
  )
);

assistform.header = true;
assistform.footer = true;

export const assistcreate = assistform;
export const assistedit = assistform;
