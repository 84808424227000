import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t } from 'zedevs-locales';

import {
  translatePrinter,
  buttonGroupPrinter,
  dateFromNowPrinter,
} from '../../elements/printers';
import { Table, Column } from '../../elements/table';

import Icon from '../../elements/Icon';

function AssistList({ assists = [] }) {
  return (
    <Table items={assists}>
      <Column
        title={t('fields.creationDate.input-label')}
        itemKey="created"
        printer={dateFromNowPrinter()}
      />
      <Column itemKey="title" />
      <Column
        title={t('fields.status.input-label')}
        itemKey="step"
        printer={translatePrinter(['assist', 'steps'])}
      />
      <Column
        title=""
        printer={buttonGroupPrinter([
          {
            label: (
              <span>
                {t('commons.display')}
                &nbsp;
                <Icon name="display" />
              </span>
            ),
            color: 'primary',
            navigation: {
              section: 'assist',
              display: 'view',
              uuid: ({ uuid }) => uuid,
            },
          },
        ])}
        align="center"
      />
    </Table>
  );
}

export default dataWrapper({ assists: ['data', 'assists'] }, AssistList);
