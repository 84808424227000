import React, { Component } from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from './high-order/actions';

import Logo from './elements/Logo';
import ProfileMenu from './layout/ProfileMenu';
import DropDown from './elements/DropDown';
import Navigation from './elements/Navigation';

class Header extends Component {
  constructor() {
    super();
    this.adminSection = this.adminSection.bind(this);
  }

  adminSection(id) {
    const { actions } = this.props;
    const pages = {
      adminorganizations: { section: 'admin', display: 'organizations' },
      adminskills: { section: 'admin', display: 'skills' },
    };
    actions.navigation.open(pages[id]);
  }

  renderLink(text, section = null, display = null) {
    const { page } = this.props;
    return (
      <Navigation
        className={[
          'nav-link',
          page.section === section && page.display === display ? 'active' : '',
        ].join(' ')}
        section={section}
        display={display}
      >
        {t(text)}
      </Navigation>
    );
  }

  render() {
    const { user = {}, layout, loading } = this.props;

    return (
      <div className="header">
        <div className="content">
          <Navigation>
            <Logo />
          </Navigation>
          <ul className="nav justify-content-center">
            <li className="nav-item">
              {this.renderLink('titles.new.assist', 'assist', 'create')}
            </li>
            <li className="nav-item">
              {this.renderLink('titles.search.assists', 'assist', 'list')}
            </li>
            <li className="nav-item">
              {this.renderLink('titles.search.profiles', 'member', 'search')}
            </li>
            {user.isOwner && (
              <li className="nav-item">
                <DropDown
                  current={layout}
                  title={t('navigation.admin')}
                  items={[
                    {
                      value: 'adminorganizations',
                      label: t('titles.list.organizations'),
                    },
                    { value: 'adminskills', label: t('titles.list.skills') },
                  ]}
                  callback={this.adminSection}
                  classNames={['nav-link']}
                />
              </li>
            )}
          </ul>

          <div>
            <ProfileMenu />
          </div>
        </div>
        <div className="progress">
          <div className={`progress-bar ${loading ? 'active' : ''}`} />
        </div>
      </div>
    );
  }
}

export default dataWrapper(
  {
    loading: ['navigation', 'loading'],
    page: ['navigation', 'page'],
    user: ['data', 'user'],
    layout: ['page'],
  },
  actionsWrapper(['navigation'], Header)
);
