import logger from './logger';

export function bound(value, min = 0, max) {
  return Math.max(Math.min(value, max), min);
}

export function gridBound(value) {
  return bound(value, 1, 12);
}

const _ = undefined;
export const commonSizes = {
  small: { cols: [1], sizes: [8, _, _, _, 9] },
  simple: { cols: [1], sizes: [12, 8, 9] },
  double: { cols: [1, _, 2], sizes: [12, 8, 12, 7, 8] },
  triple: { cols: [1, _, 3], sizes: [12, 8, 12, 7, 8] },
  quadruple: { cols: [1, 2, 4], sizes: [12] },
};

export function columnsToGrid(...counts) {
  return counts.map((count) => {
    if (count === undefined) return count;
    if (![1, 2, 3, 4, 6, 12].includes(count)) {
      logger.warning('Invalid colum count, fallback to 1', { count });
      return 12;
    }

    return 12 / count;
  });
}

export function getGridComplement(size) {
  return 12 - size || 12;
}

export function sizeToGrid(prefix, complement, ...definitions) {
  return ['', '-sm', '-md', '-lg', '-xl']
    .map((classSufix, index) => {
      if (!definitions[index]) return undefined;

      const value = gridBound(definitions[index]);
      return `${prefix}${classSufix}-${
        complement ? getGridComplement(value) : value
      }`;
    })
    .filter((c) => c);
}
