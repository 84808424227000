import React from 'react';
import { t } from 'zedevs-locales';
import { branch as dataWrapper } from 'baobab-react/higher-order';

import { branch as actionsWrapper } from '../../high-order/actions';

import FormEntry from '../../elements/FormEntry';
import Tags from '../../elements/Tags';

import { commonSizes } from '../../../utils';

function AssistSearch({ actions, hooks }) {
  const { onSubmit, addInputRef, submit } = hooks.useForm({
    action({ description, keywords, isPublic }, callback) {
      actions.navigation.setMetadata(
        {
          metadata: { assistFilters: { description, keywords, isPublic } },
        },
        callback
      );
    },
    button: {
      label: 'commons.search',
    },
  });

  return (
    <form onSubmit={onSubmit} className="row">
      <h2 className="col-12">{t('commons.search')}</h2>
      <FormEntry id="description" {...commonSizes.simple}>
        <input
          type="text"
          className="form-control"
          id="description"
          ref={addInputRef}
        />
      </FormEntry>
      <FormEntry id="keywords">
        <Tags id="keywords" ref={addInputRef} />
      </FormEntry>
      <div className="form-group submit col-12">{submit}</div>
    </form>
  );
}

export default dataWrapper(
  { features: ['data', 'user', 'features'] },
  actionsWrapper(['navigation'], AssistSearch)
);
