import React from 'react';

import { branch as actionsWrapper } from '../high-order/actions';

export default actionsWrapper(
  ['navigation'],
  function Navigation({
    actions,
    type,
    children,
    section,
    display,
    container = 'page',
    uuid,
    metadata = {},
    className,
  }) {
    const Tag = type === undefined ? 'a' : 'button';
    return (
      <Tag
        type={type}
        href={Tag === 'a' ? '#' : undefined}
        className={className}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          actions.navigation.open({
            section,
            display,
            uuid,
            metadata,
            container,
          });
        }}
      >
        {children}
      </Tag>
    );
  }
);
