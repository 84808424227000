import { pickBy, defaults } from 'lodash';
import { serviceToStateCallbackFactory } from '../utils';

export default {
  create({ clients, callback }, { data }) {
    clients.api.assist.create({ data }, callback);
  },
  list({ state, clients, callback }, { data }) {
    const assistFilters =
      state.get(['navigation', 'page', 'metadata', 'assistFilters']) || {};

    clients.api.assist.list(
      {
        data: pickBy(defaults(data, assistFilters), (v) => v !== undefined),
      },
      serviceToStateCallbackFactory(state, ['data', 'assists'], callback)
    );
  },
  get({ state, clients, callback }, { uuid }) {
    clients.api.assist.get(
      { params: { assist: uuid } },
      serviceToStateCallbackFactory(state, ['data', 'assist'], callback)
    );
  },
  update({ clients, callback }, { uuid, data }) {
    clients.api.assist.update({ params: { assist: uuid }, data }, callback);
  },
  getPlan({ state, clients, callback }, { uuid }) {
    clients.api.assist.getPlan(
      { params: { assist: uuid } },
      serviceToStateCallbackFactory(state, ['data', 'plan'], callback)
    );
  },
  getGuests({ state, clients, callback }, { uuid }) {
    clients.api.assist.getGuests(
      { params: { assist: uuid } },
      serviceToStateCallbackFactory(state, ['data', 'guests'], callback)
    );
  },
  getResponses({ state, clients, callback }, { uuid }) {
    clients.api.assist.getResponses(
      { params: { assist: uuid } },
      serviceToStateCallbackFactory(state, ['data', 'responses'], callback)
    );
  },
  getIntervention({ state, clients, callback }, { uuid }) {
    clients.api.assist.getIntervention(
      { params: { assist: uuid } },
      serviceToStateCallbackFactory(state, ['data', 'intervention'], callback)
    );
  },
  respond({ clients, callback }, { assist, data }) {
    clients.api.assist.respond({ params: { assist }, data }, callback);
  },
  setStep({ state, clients, callback }, { assist, step }) {
    clients.api.assist.setStep(
      { params: { assist }, data: { step } },
      serviceToStateCallbackFactory(state, ['data', 'assist'], callback)
    );
  },
  publish({ actions, callback }, { assist }) {
    actions.assist.setStep({ assist, step: 1 }, callback);
  },
  unpublish({ actions, callback }, { assist }) {
    actions.assist.setStep({ assist, step: 0 }, callback);
  },
  close({ actions, callback }, { assist }) {
    actions.assist.setStep({ assist, step: 3 }, callback);
  },
  addGuest({ clients, callback }, { assist, email }) {
    clients.api.assist.addGuest(
      { params: { assist }, data: { email } },
      (err, res) => {
        if (err) return callback(err);
        return callback(null, res);
      }
    );
  },
};
