import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t } from 'zedevs-locales';

import Icon from '../elements/Icon';

import Form from '../display/organization/Form';

export const myorganization = dataWrapper(
  {
    user: ['data', 'user'],
    organization: ['data', 'organization'],
  },
  ({ user, organization = {} }) => {
    return (
      <div className="organization">
        <h1 className="row">
          <span className="col-10">{t('titles.my.organization')}</span>
          <span className="title-icon col-2">
            <Icon name="organization" />
          </span>
        </h1>
        <Form organization={organization} readOnly={user.role !== 'admin'} />
      </div>
    );
  }
);

myorganization.header = true;
myorganization.footer = true;
