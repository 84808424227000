import React from 'react';

import {
  datePrinter,
  durationPrinter,
  numberPrinter,
} from '../../elements/printers';
import { Table, Column } from '../../elements/table';

export default function History({ interventions = [], flags = {} }) {
  const { isClient, isProvider } = flags;
  return (
    <Table
      items={interventions.map((i) => {
        const duration = i.closed ? i.closed - i.start : null;
        let price, cost;

        if (duration) {
          price =
            (i.response.price / (i.response.duration * 3600 * 1000)) * duration;
          cost =
            (i.response.cost / (i.response.duration * 3600 * 1000)) * duration;
        }
        return {
          name: i.assist.title,
          date: i.start,
          duration,
          price,
          cost,
        };
      })}
    >
      <Column itemKey="name" />
      <Column itemKey="date" printer={datePrinter()} />
      <Column itemKey="duration" printer={durationPrinter()} />
      {isClient && (
        <Column
          itemKey="price"
          printer={numberPrinter({ unit: '€ HT', precision: 2 })}
        />
      )}
      {isProvider && (
        <Column
          itemKey="cost"
          printer={numberPrinter({ unit: '€ HT', precision: 2 })}
        />
      )}
    </Table>
  );
}
