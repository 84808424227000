import React from 'react';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../high-order/actions';
import Icon from './Icon';

function DeleteModal({ actions, type, item, callback = () => {} }) {
  return (
    <div className="container">
      <div className="row">
        <h1 className="col-12">
          <span className="col-10">{t(['titles', 'delete', type])}</span>
          <span className="title-icon col-2">
            <Icon name={type} />
          </span>
        </h1>
        <p className="col-12">{t('commons.deleteValidation', { item })}</p>
        <p className="col-12">{t('commons.noCancel')}</p>
        <div className="col-12 text-center mb-4">
          <div className="btn-group">
            <button
              className="btn btn-outline-danger"
              onClick={() => callback()}
            >
              {t('commons.confirm')}
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={() => actions.navigation.close()}
            >
              {t('commons.cancel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default actionsWrapper(['navigation'], DeleteModal);
