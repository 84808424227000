import React from 'react';
import { t } from 'zedevs-locales';

import FormEntry from '../../elements/FormEntry';

import { commonSizes } from '../../../utils';

export default function ProfilePersonalInformation({
  member = {},
  addInputRef,
}) {
  return (
    <div className="personnal-information">
      <h2 className="col-12">{t('profile.personnal-information')}</h2>
      <FormEntry id="firstname" {...commonSizes.double}>
        <input
          type="text"
          className="form-control"
          id="firstname"
          defaultValue={member.firstname}
          key={member.uuid}
          ref={addInputRef}
        />
      </FormEntry>
      <FormEntry id="lastname" {...commonSizes.double}>
        <input
          type="text"
          className="form-control"
          id="lastname"
          defaultValue={member.lastname}
          key={member.uuid}
          ref={addInputRef}
        />
      </FormEntry>
      <FormEntry id="email" {...commonSizes.double}>
        <input
          type="email"
          className="form-control"
          id="email"
          defaultValue={member.email}
          disabled={!!member.organization}
          key={member.uuid}
          ref={addInputRef}
        />
      </FormEntry>
      <FormEntry id="phone" {...commonSizes.double}>
        <input
          type="phone"
          className="form-control"
          id="phone"
          defaultValue={member.phone}
          key={member.uuid}
          ref={addInputRef}
        />
      </FormEntry>
    </div>
  );
}
