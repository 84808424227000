import Baobab from 'baobab';

import logger from '../utils/logger';

const state = new Baobab(
  {
    locale: 'fr-FR',
    logged: false,
    navigation: {
      loading: 0,
      page: {
        timestamp: Date.now(),
        section: null,
        uuid: null,
        display: null,
        metadata: {},
      },
      modal: {
        timestamp: Date.now(),
        section: null,
        uuid: null,
        display: null,
      },
      tooltip: {
        reference: null,
        component: null,
        props: null,
      },
    },
    data: {},
    toasts: [],
    page: Baobab.monkey({
      cursors: {
        logged: ['logged'],
        navigation: ['navigation', 'page'],
      },
      get({ logged, navigation }) {
        const unloggedPages = [
          'profileregister',
          'passwordforgot',
          'passwordreset',
          'interventionguest',
        ];
        const page = [
          navigation.section || 'home',
          navigation.display || '',
        ].join('');

        if (logged && unloggedPages.includes(page)) return 'home';
        if (!logged && !unloggedPages.includes(page)) return 'login';
        return page;
      },
    }),
    modal: Baobab.monkey({
      cursors: {
        logged: ['logged'],
        navigation: ['navigation', 'modal'],
      },
      get({ logged, navigation }) {
        if (!logged) return false;
        return [navigation.section || '', navigation.display || ''].join('');
      },
    }),
  },
  {
    cursorSingletons: false,
  }
);

state.on('update', (event) => {
  event.data.paths.forEach((path) => {
    logger.debug(`STATE[${path.join('.')}]`, state.get(path));
  });
});

export default state;
