import { pickBy, defaults } from 'lodash';
import { serviceToStateCallbackFactory } from '../utils';

export default {
  get({ clients, state, callback }, { uuid }) {
    if (!uuid) return callback();
    return clients.api.skills.get(
      { params: { skill: uuid } },
      serviceToStateCallbackFactory(state, ['data', 'skill'], callback)
    );
  },
  list({ clients, state, callback }, { name, categories, approved }) {
    const skillFilters =
      state.get(['navigation', 'page', 'metadata', 'skillFilters']) || {};

    clients.api.skills.list(
      {
        data: pickBy(
          defaults({ name, categories, approved }, skillFilters),
          (v) => v !== undefined
        ),
      },
      serviceToStateCallbackFactory(state, ['data', 'skills'], callback)
    );
  },
  autocomplete({ clients, callback }, { name }) {
    clients.api.skills.autocomplete({ data: { name } }, callback);
  },
  create({ clients, callback }, { data }) {
    clients.api.skills.create({ params: {}, data }, callback);
  },
  update({ clients, callback }, { uuid, data }) {
    clients.api.skills.update({ params: { skill: uuid }, data }, callback);
  },
};
