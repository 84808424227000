import React from 'react';
import { transform } from 'lodash';

import Navigation from '../Navigation';

export const buttonGroupPrinter = (buttons = []) => {
  return function ButtonGroupPrinter({ item }) {
    return (
      <div className="btn-group" role="group">
        {buttons
          .filter(
            ({ display }) =>
              display === undefined ||
              display === true ||
              (typeof display === 'function' && display(item))
          )
          .map(
            (
              {
                label,
                color = 'outline-secondary',
                callback = () => {},
                navigation,
              },
              index
            ) => {
              const Tag = navigation ? Navigation : 'button';
              const properties = transform(
                navigation || {},
                (props, getter, prop) => {
                  props[prop] =
                    typeof getter === 'function' ? getter(item) : getter;
                  return props;
                },
                {
                  className: `btn btn-sm btn-${color}`,
                  onClick: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    callback(item);
                  },
                }
              );

              return (
                <Tag {...properties} key={`button-${index}`}>
                  {label}
                </Tag>
              );
            }
          )}
      </div>
    );
  };
};
