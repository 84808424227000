import { serviceToStateCallbackFactory } from '../utils';

export default {
  create({ clients, callback }, { data }) {
    clients.api.organization.create({ params: {}, data }, (err, res) => {
      if (err) return callback(err);
      return callback(null, res);
    });
  },

  list({ state, clients, callback }) {
    clients.api.organization.list(
      {},
      serviceToStateCallbackFactory(state, ['data', 'organizations'], callback)
    );
  },

  get({ state, clients, callback }, { uuid, data }) {
    const organization = uuid || state.get(['data', 'user', 'organization']);

    clients.api.organization.get(
      { params: { organization }, data },
      serviceToStateCallbackFactory(state, ['data', 'organization'], callback)
    );
  },

  update({ state, clients, callback }, { uuid, data }) {
    const organization = uuid || state.get(['data', 'user', 'organization']);

    clients.api.organization.update(
      { params: { organization }, data },
      serviceToStateCallbackFactory(state, ['data', 'organization'], callback)
    );
  },

  members({ state, clients, callback }, { uuid }) {
    const organization = uuid || state.get(['data', 'user', 'organization']);

    clients.api.organization.members(
      { params: { organization } },
      serviceToStateCallbackFactory(state, ['data', 'members'], callback)
    );
  },

  invite({ clients, callback }, { organization, data }) {
    clients.api.organization.invite(
      { params: { organization }, data },
      callback
    );
  },

  grant({ clients, callback }, { uuid, organization, role }) {
    clients.api.organization.grant(
      { params: { organization, uuid }, data: { role } },
      callback
    );
  },

  plans({ state, clients, callback }, { uuid }) {
    const organization = uuid || state.get(['data', 'user', 'organization']);

    clients.api.organization.plans(
      { params: { organization } },
      serviceToStateCallbackFactory(state, ['data', 'plans'], callback)
    );
  },
};
