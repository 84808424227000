import React from 'react';
import { t } from 'zedevs-locales';

import {
  buttonGroupPrinter,
  dateFromNowPrinter,
} from '../../elements/printers';
import { Table, Column } from '../../elements/table';

import Icon from '../../elements/Icon';

export default function List({ providers = [], admin = false }) {
  const buttons = [
    {
      label: (
        <span>
          {t(['commons', 'display'])}
          &nbsp;
          <Icon name={'display'} />
        </span>
      ),
      color: 'primary',
      navigation: {
        section: 'provider',
        display: 'view',
        container: 'modal',
        uuid: ({ uuid }) => uuid,
      },
    },
  ];

  if (admin) {
    buttons.push({
      label: (
        <span>
          {t('commons.edit')}
          &nbsp;
          <Icon name={'edit'} />
        </span>
      ),
      color: 'primary',
      navigation: {
        section: 'provider',
        display: 'update',
        container: 'modal',
        uuid: ({ uuid }) => uuid,
      },
    });
  }

  return (
    <Table items={providers}>
      <Column itemKey="name" />
      <Column
        title={t('fields.expiresAt.input-label')}
        itemKey="expiresAt"
        printer={dateFromNowPrinter()}
      />
      <Column title="" printer={buttonGroupPrinter(buttons)} align="center" />
    </Table>
  );
}
