import React, { Component } from 'react';

class DropDown extends Component {
  constructor() {
    super();

    this.state = { open: false };
    this.handleToggle = this.handleToggle.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleToggle, false);
  }

  handleToggle(e) {
    const open = !this.state.open;
    this.setState({ open });

    if (open) {
      document.addEventListener('click', this.handleToggle, false);
    } else {
      document.removeEventListener('click', this.handleToggle, false);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  handleSelect(e) {
    const { callback = () => {} } = this.props;
    callback(e.target.dataset.value);
  }

  render() {
    const {
      title,
      caret = false,
      items = [],
      current,
      alignment = 'left',
      direction = 'down',
      classNames = [],
    } = this.props;
    const { open } = this.state;
    const show = open ? 'show' : '';

    const rootClassNames = [
      'dropdown',
      show,
      direction === 'up' ? 'dropup' : '',
      ...classNames,
    ].join(' ');

    const menuClassNames = [
      'dropdown-menu',
      show,
      alignment === 'right' ? 'dropdown-menu-right' : '',
    ].join(' ');

    const active = items.reduce(
      (flag, { value }) => flag || current === value,
      false
    );

    return (
      <div className={rootClassNames}>
        <a
          className={`dropdown-toggle ${active ? 'active' : ''}`}
          role="button"
          onClick={this.handleToggle}
          href="#"
        >
          {title}
          {caret ? <span className="caret"></span> : undefined}
        </a>
        <div className={menuClassNames}>
          {items.map(({ label, value }) => (
            <a
              className={[
                'dropdown-item',
                value === current ? 'active' : '',
              ].join(' ')}
              href=""
              onClick={this.handleSelect}
              data-value={value}
              key={value}
            >
              {label}
            </a>
          ))}
        </div>
      </div>
    );
  }
}

export default DropDown;
