import useForm from './form';
import useScript from './script';
import useTime from './time';
import useJitsi from './jitsi';

export default (actions) => ({
  useForm: useForm(actions),
  useScript: useScript(actions),
  useTime: useTime(actions),
  useJitsi: useJitsi(actions),
});
