import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t, LocaleDate } from 'zedevs-locales';

import { branch as actionsWrapper } from '../high-order/actions';

import Icon from '../elements/Icon';
import Toolbar from '../elements/calendar/Toolbar';
import Grid from '../elements/calendar/Grid';

function MyAvailabilities({
  actions,
  availabilities = [],
  interventions = [],
  metadata: { view = 'week', date = Date.now() },
}) {
  const upsert = ({ id, start, end }) => {
    actions.profile.setAvailabilities(
      {
        data: {
          uuid: id,
          start: new LocaleDate(start).getTime(),
          stop: new LocaleDate(end).getTime(),
        },
      },
      actions.toast.wrapper({
        error: 'availability-error',
        success: 'availability-success',
        callback: () => actions.navigation.reload(),
      })
    );
  };

  return (
    <div className="availabilities">
      <h1 className="row">
        <span className="col-10">{t('titles.my.availabilities')}</span>
        <span className="title-icon col-2">
          <Icon name="intervention" />
        </span>
      </h1>
      <Toolbar date={date} view={view} />
      <Grid
        date={date}
        view={view}
        events={[
          ...availabilities.map((availability) => ({
            id: availability.uuid,
            extendedProps: availability,
            start: new LocaleDate(availability.start),
            end: new LocaleDate(availability.stop),
            classNames: ['event'],
          })),
          ...interventions.map((intervention) => ({
            id: intervention.uuid,
            extendedProps: intervention,
            title: intervention.assist.title,
            start: new LocaleDate(intervention.start),
            end: new LocaleDate(intervention.start).add(
              intervention.response.duration,
              'hour'
            ),
            editable: false,
            classNames: ['event', 'intervention'],
          })),
        ]}
        selectable
        editable
        selectMirror={true}
        onSelect={upsert}
        onDrop={upsert}
        onResize={upsert}
        onDoubleClick={({ id, start }) => upsert({ id, start, end: start })}
      />
    </div>
  );
}
export const myavailabilities = dataWrapper(
  {
    availabilities: ['data', 'availabilities'],
    interventions: ['data', 'interventions'],
    metadata: ['navigation', 'page', 'metadata'],
  },
  actionsWrapper(['navigation', 'toast', 'profile'], MyAvailabilities)
);

myavailabilities.header = true;
myavailabilities.footer = true;
