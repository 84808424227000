import { serviceToStateCallbackFactory } from '../utils';

export default {
  create({ state, clients, callback }, { data: { organization, ...data } }) {
    clients.api.organization.createPlan(
      { params: { organization }, data },
      serviceToStateCallbackFactory(state, ['data', 'plan'], callback)
    );
  },
  get({ state, clients, callback }, { uuid }) {
    clients.api.plan.get(
      { params: { plan: uuid } },
      serviceToStateCallbackFactory(state, ['data', 'plan'], callback)
    );
  },
  addApplicant({ clients, callback }, { plan, applicant }) {
    clients.api.plan.addApplicant(
      { params: { plan }, data: { applicant } },
      callback
    );
  },
  removeApplicant({ clients, callback }, { plan, applicant }) {
    clients.api.plan.removeApplicant({ params: { plan, applicant } }, callback);
  },
  getCandidates({ state, clients, callback }, { uuid }) {
    clients.api.plan.getCandidates(
      { params: { plan: uuid } },
      serviceToStateCallbackFactory(
        state,
        ['data', 'applicantCandidates'],
        callback
      )
    );
  },
  addProvider({ clients, callback }, { plan, provider }) {
    clients.api.plan.addProvider(
      { params: { plan }, data: { ...provider } },
      callback
    );
  },
  update({ state, clients, callback }, { uuid, data }) {
    clients.api.plan.update(
      { params: { plan: uuid }, data },
      serviceToStateCallbackFactory(state, ['data', 'plan'], callback)
    );
  },
  addCredit({ clients, callback }, { plan, amount }) {
    clients.api.plan.addCredit(
      { params: { plan }, data: { amount } },
      callback
    );
  },
};
