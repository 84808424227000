import { useEffect, useState } from 'react';

export default () => ({ interval = 1000, shouldStop = () => false }) => {
  const [isStoped, stop] = useState();
  const [, setTime] = useState();
  const [handler, setHandler] = useState(null);

  const iterator = () => {
    setTime(Date.now());
    if (!shouldStop()) {
      setHandler(setTimeout(iterator, interval));
    } else {
      stop(true);
    }

    return () => (handler ? clearTimeout(handler) : undefined);
  };

  useEffect(iterator, [interval]);

  return isStoped;
};
