import React from 'react';
import { find } from 'lodash';
import { t } from 'zedevs-locales';

import { branch as dataWrapper } from 'baobab-react/higher-order';

import { branch as actionsWrapper } from '../high-order/actions';

import Icon from '../elements/Icon';
import FormEntry from '../elements/FormEntry';
import ProfileSelection from '../elements/ProfileSelection';
import { commonSizes } from '../../utils';

function YYYYMMDD(date) {
  const day = ('00' + date.getDate()).slice(-2);
  const month = ('00' + (date.getMonth() + 1)).slice(-2);
  const year = date.getFullYear();

  return `${year}-${month}-${day}`;
}

function ProviderModal({
  organizations = [],
  plan,
  provider,
  candidates = [],
  actions,
  hooks,
}) {
  const current = provider || {
    provider: undefined,
    saleRate: 100,
    purchaseRate: 80,
    expiresAt: undefined,
    experts: [],
  };
  const mode = provider ? 'update' : 'create';

  const expiresAt = new Date(current.expiresAt);

  const { addInputRef, submit } = hooks.useForm({
    action: (data, callback) => {
      if (provider) {
        actions.provider.update({ uuid: provider.uuid, data }, callback);
      } else {
        actions.plan.addProvider({ plan: plan.uuid, provider: data }, callback);
      }
    },
    callback(err, item) {
      if (!err) {
        if (provider) {
          actions.navigation.close();
        } else {
          actions.navigation.open({
            container: 'modal',
            section: 'provider',
            display: 'update',
            uuid: item.uuid,
          });
        }
        actions.navigation.reload();
      }
    },
    error: `provider-${mode}-error`,
    success: `provider-${mode}-success`,
    button: {
      useClick: true,
    },
  });

  const selection = (current.experts || [])
    .filter(({ uuid }) => uuid)
    .map(({ uuid, profile: { firstname, lastname, email } }) => ({
      key: uuid,
      label: `${firstname} ${lastname} (${email})`,
    }));
  const searchCallback = (value) =>
    candidates
      .filter(
        ({ uuid, lastname, firstname, email }) =>
          (lastname.toLowerCase().match(value.toLowerCase()) ||
            firstname.toLowerCase().match(value.toLowerCase()) ||
            email.toLowerCase().match(value.toLowerCase())) &&
          !find(
            current.experts,
            (expert) => expert.profile.uuid === uuid && expert.uuid
          )
      )
      .map(({ uuid, firstname, lastname, email }) => ({
        key: uuid,
        label: `${firstname} ${lastname} (${email})`,
      }));
  const addCallback = (expert) => {
    actions.provider.addExpert(
      { expert, provider: provider.uuid },
      actions.toast.wrapper({
        error: 'expert-add-error',
        success: 'expert-add-success',
        callback: () => actions.navigation.reload({ container: 'modal' }),
      })
    );
  };
  const removeCallback = (expert) => {
    actions.provider.removeExpert(
      { expert, provider: provider.uuid },
      actions.toast.wrapper({
        error: 'expert-remove-error',
        success: 'expert-remove-success',
        callback: () => actions.navigation.reload({ container: 'modal' }),
      })
    );
  };

  return (
    <div className="provider">
      <h1 className="row">
        <span className="col-10">{t('titles.new.provider')}</span>
        <span className="title-icon col-2">
          <Icon name="organization" />
        </span>
      </h1>
      <div className="row scrollable">
        <div className="container">
          <form className="row">
            <FormEntry id="provider" {...commonSizes.simple}>
              <select
                className="form-control"
                id="provider"
                ref={addInputRef}
                defaultValue={current.provider}
                key={current.uuid}
              >
                {organizations.map((organization) => (
                  <option key={organization.uuid} value={organization.uuid}>
                    {organization.name}
                  </option>
                ))}
              </select>
            </FormEntry>
            <FormEntry id="saleRate" {...commonSizes.simple}>
              <div className="input-group">
                <input
                  type="number"
                  min="1"
                  defaultValue={current.saleRate}
                  className="form-control"
                  id="saleRate"
                  ref={addInputRef}
                  key={current.uuid}
                />
                <div className="input-group-append">
                  <div className="input-group-text">€/h HT</div>
                </div>
              </div>
            </FormEntry>
            <FormEntry id="purchaseRate" {...commonSizes.simple}>
              <div className="input-group">
                <input
                  type="number"
                  min="1"
                  defaultValue={current.purchaseRate}
                  className="form-control"
                  id="purchaseRate"
                  ref={addInputRef}
                  key={current.uuid}
                />
                <div className="input-group-append">
                  <div className="input-group-text">€/h HT</div>
                </div>
              </div>
            </FormEntry>
            <FormEntry id="expiresAt" {...commonSizes.simple}>
              <input
                type="date"
                className="form-control text-right"
                id="expiresAt"
                defaultValue={
                  current.expiresAt ? YYYYMMDD(expiresAt) : undefined
                }
                ref={addInputRef}
                key={current.uuid}
              />
            </FormEntry>
          </form>
        </div>
        {provider && (
          <div className="container">
            <h2>{t('plans.experts')}</h2>
            <ProfileSelection
              emptyText={t('plans.no-expert')}
              direction="up"
              selection={selection}
              searchCallback={searchCallback}
              addCallback={addCallback}
              removeCallback={removeCallback}
            />
          </div>
        )}
      </div>
      <div className="row">
        <div className="form-group submit col-12">
          {submit}
          <button
            className="btn btn-outline-dark"
            type="reset"
            onClick={() => actions.navigation.close()}
          >
            {t('commons.cancel')}
          </button>
        </div>
      </div>
    </div>
  );
}

const providermodal = dataWrapper(
  {
    plan: ['data', 'plan'],
    provider: ['data', 'provider'],
    organizations: ['data', 'organizations'],
    candidates: ['data', 'expertCandidates'],
  },
  actionsWrapper(['plan', 'provider', 'navigation', 'toast'], ProviderModal)
);

export const providercreate = providermodal;
export const providerupdate = providermodal;
