import React from 'react';

import { branch as actionsWrapper } from '../../high-order/actions';

import Icon from '../../elements/Icon';

import FormEntry from '../../elements/FormEntry';
import Tags from '../../elements/Tags';
import { commonSizes } from '../../../utils';

function SkillDetails({ actions, hooks, skill = {} }) {
  const mode = skill.uuid ? 'update' : 'create';

  const { onSubmit, addInputRef, submit } = hooks.useForm({
    action: (data, callback) =>
      actions.skills[mode](
        {
          uuid: skill.uuid,
          data,
        },
        callback
      ),
    error: `skill-${mode}-error`,
    success: `skill-${mode}-success`,
    callback: (err) => {
      actions.navigation.open({
        section: 'admin',
        display: 'skills',
      });
      if (!err) actions.navigation.close();
    },
    button: {
      label: skill.uuid ? 'commons.save' : 'commons.add',
      icon: skill.uuid ? <Icon name="save" /> : <Icon name="add" />,
    },
  });

  return (
    <form className="row" onSubmit={onSubmit}>
      <FormEntry id="name" {...commonSizes.simple}>
        <input
          type="text"
          className="form-control"
          id="name"
          key={skill.uuid}
          defaultValue={skill.name}
          ref={addInputRef}
        />
      </FormEntry>
      <FormEntry id="categories">
        <Tags
          id="categories"
          defaultValue={skill.categories}
          ref={addInputRef}
        />
      </FormEntry>
      <FormEntry id="approved" {...commonSizes.simple}>
        <div className="custom-control custom-switch">
          <input
            type="checkbox"
            defaultChecked={skill.approved}
            className="custom-control-input"
            id="approved"
            ref={addInputRef}
          />
          <label className="custom-control-label" htmlFor="approved" />
        </div>
      </FormEntry>
      <div className="form-group submit col-12">{submit}</div>
    </form>
  );
}
export default actionsWrapper(['skills', 'navigation'], SkillDetails);
