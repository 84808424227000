import React, { useEffect, useRef } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';

import { views } from './commons';

export default function Grid({
  date,
  view,
  onSelect = () => {},
  onDrop = () => {},
  onResize = () => {},
  onDoubleClick = () => {},
  ...props
}) {
  const calendar = useRef();

  const getApi = () => {
    if (!calendar || !calendar.current) return undefined;
    return calendar.current.getApi();
  };

  useEffect(() => {
    const API = getApi();
    return API && API.changeView(views[view].grid);
  }, [calendar, view]);

  useEffect(() => {
    const API = getApi();
    return API && API.gotoDate(date);
  }, [calendar, date]);

  return (
    <div className="row grid">
      <div className="col-12">
        <FullCalendar
          height="100%"
          headerToolbar={false}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={views[view].grid}
          initialDate={date}
          locale="fr"
          firstDay={1}
          ref={calendar}
          allDaySlot={false}
          {...props}
          select={(data) => {
            const API = getApi();
            API && API.unselect();
            onSelect(data);
          }}
          eventDrop={({ event }) => onDrop(event)}
          eventResize={({ event }) => onResize(event)}
          eventDidMount={({ event, el }) =>
            el.addEventListener('dblclick', () => onDoubleClick(event))
          }
        />
      </div>
    </div>
  );
}
