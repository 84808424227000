import { v4 } from 'uuid';
import { t } from 'zedevs-locales';

export default {
  post({ state, actions, callback }, { level, message, duration = 10 * 1000 }) {
    const uuid = v4();
    const toasts = state.select('toasts');
    toasts.push({ uuid, level, message });
    toasts.set(toasts.get().slice(-5));
    setTimeout(() => actions.toast.remove({ uuid }), duration);
    callback(null, { uuid });
  },
  remove({ state, callback }, { uuid }) {
    const toasts = state.select('toasts');
    toasts.set(toasts.get().filter((toast) => toast.uuid !== uuid));
    callback();
  },

  invalidData({ actions }, { value, path }) {
    const field = path.pop();

    actions.toast.error({
      message: t(
        value === undefined
          ? 'toast.mandatory-field'
          : 'toast.invalid-field-data',
        {
          value,
          field: t(['fields', field, 'description']),
        }
      ),
    });
  },

  wrapper({ state, actions, logger }, { error, success, callback = () => {} }) {
    return (err, data) => {
      if (err) {
        const { xhr } = err;
        if (xhr) {
          let details;
          try {
            details = JSON.parse(xhr.responseText);
          } catch (e) {
            logger.error(e);
          }

          if (xhr.status === 400 && 'path' in details) {
            actions.toast.invalidData(details);
          } else if (error) {
            actions.toast.error({ message: t(['toast', error]) });
          } else {
            actions.toast.error({ message: t('toast.generic-api-error') });
          }

          callback(err, data);
        } else {
          if (
            error &&
            !(
              err.uuid &&
              state.select(['toasts', ({ uuid }) => uuid === err.uuid]).get()
            )
          ) {
            actions.toast.error({ message: t(['toast', error]) });
          }
          callback(err, data);
        }
      } else {
        if (success) {
          actions.toast.success({ message: t(['toast', success]) });
        }
        callback(null, data);
      }
    };
  },

  ...['info', 'success', 'warning', 'error'].reduce((levels, level) => {
    levels[level] = ({ actions, callback }, { message, duration }) =>
      actions.toast.post({ level, message, duration }, callback);
    return levels;
  }, {}),
};
