import React from 'react';

import { defaultPrinter } from '../printers';

export function Column({ itemKey, printer, item, align = 'left' }) {
  const Printer = printer || defaultPrinter(itemKey);
  return (
    <td className={`text-${align}`}>
      <Printer item={item} itemKey={itemKey} />
    </td>
  );
}
