import React, { Component } from 'react';
import { t } from 'zedevs-locales';

class LoadingButton extends Component {
  constructor() {
    super();

    this.state = { loading: false };
    this.startLoading = this.startLoading.bind(this);
    this.stopLoading = this.stopLoading.bind(this);
  }

  get loading() {
    const { loading } = this.state;
    return loading;
  }
  startLoading() {
    this.setState({ loading: true });
  }

  stopLoading() {
    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;
    const {
      label = 'commons.submit',
      color = 'primary',
      type = 'submit',
      icon,
      onClick,
    } = this.props;

    return (
      <button
        type={type}
        className={['btn', `btn-${color}`].join(' ')}
        onClick={onClick}
      >
        <span>{t(label || 'commons.submit')}</span>
        &nbsp;
        {loading ? (
          <span className="spinner-border spinner-border-sm"></span>
        ) : (
          icon
        )}
      </button>
    );
  }
}

export default LoadingButton;
