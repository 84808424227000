import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t } from 'zedevs-locales';

import ProfileSelection from '../../elements/ProfileSelection';
import History from './History';

import Icon from '../../elements/Icon';

export default dataWrapper(
  { user: ['data', 'user'] },
  function View({ user, provider = {}, level = 1, isClient }) {
    const { organization = {}, experts = [], isProvider } = provider;
    const Title = `h${level}`;
    const SubTitle = `h${+level + 1}`;
    return (
      <div className="provider">
        <Title className="row">
          <span className="col-10">{organization.name}</span>
          <span className="title-icon col-2">
            <Icon name="organization" />
          </span>
        </Title>
        <SubTitle>{t('plans.experts')}</SubTitle>
        {organization.uuid === user.organization && (
          <ProfileSelection
            readOnly
            emptyText={t('plans.no-expert')}
            selection={experts
              .filter(({ uuid }) => uuid)
              .map(({ uuid, profile: { firstname, lastname, email } }) => ({
                key: uuid,
                label: `${firstname} ${lastname} (${email})`,
              }))}
          />
        )}
        <SubTitle>{t('titles.list.interventions')}</SubTitle>
        <History
          interventions={provider.interventions}
          flags={{ isClient, isProvider, isOwner: user.isOwner }}
        />
      </div>
    );
  }
);
