import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';

import { branch as actionsWrapper } from './high-order/actions';

import Icon from './elements/Icon';

const commonsClasses = ['alert', 'show', 'fade'].join(' ');

const levelClasses = {
  info: 'info',
  success: 'success',
  warning: 'warning',
  error: 'danger',
};

class ModalContainer extends Component {
  constructor() {
    super();
    this.close = this.close.bind(this);
  }

  close(uuid) {
    const { actions } = this.props;
    actions.toast.remove({ uuid });
  }

  render() {
    const { toasts } = this.props;

    return (
      <div className="toasts container">
        <div className="row">
          <div className="container">
            {toasts.map(({ uuid, level, message }) => (
              <div className="row" key={uuid}>
                <div
                  className={`${commonsClasses} alert-${levelClasses[level]}`}
                >
                  <button
                    type="button"
                    className="close"
                    onClick={() => this.close(uuid)}
                  >
                    <Icon name="close" />
                  </button>
                  {message}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default branch(
  {
    toasts: ['toasts'],
  },
  actionsWrapper(['toast'], ModalContainer)
);
