import React from 'react';
import { branch } from 'baobab-react/higher-order';

import * as pages from './pages';
import Header from './Header';
import Footer from './Footer';
import Modal from './Modal';
import Toasts from './Toasts';
import Tooltip from './Tooltip';

const Layout = ({ page }) => {
  const Page = page in pages ? pages[page] : pages.home;

  return (
    <div className="layout">
      <Tooltip />
      {Page.header ? <Header /> : undefined}
      {Page.footer ? <Footer /> : undefined}
      <div className="page">
        <Page />
      </div>
      <Modal />
      <Toasts />
    </div>
  );
};

export default branch(
  {
    locale: ['locale'],
    page: ['page'],
  },
  Layout
);
