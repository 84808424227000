import React from 'react';
import { find } from 'lodash';
import { branch as dataWrapper } from 'baobab-react/higher-order';

import ProviderView from '../display/provider/View';

export const providerview = dataWrapper(
  {
    plan: ['data', 'plan'],
    uuid: ['navigation', 'modal', 'uuid'],
  },
  function ProviderViewModal({ plan = {}, uuid }) {
    const { isClient } = plan;
    const provider = find(plan.providers || [], { uuid });
    return <ProviderView provider={provider} isClient={isClient} />;
  }
);
