export default {
  get({ state, clients, callback }, { uuid }) {
    clients.api.guest.get(
      { params: { guest: uuid } },
      (err, { guest } = {}) => {
        if (!err) state.set(['data', 'guest'], guest);
        return callback(err, guest);
      }
    );
  },
  load({ state, clients, actions, callback }, { uuid }) {
    clients.api.guest.get(
      { params: { guest: uuid } },
      (err, { guest, assist, response, intervention } = {}) => {
        if (err) {
          return actions.navigation.open({}, () => callback(err));
        }
        state.set(['data', 'guest'], guest);
        state.set(['data', 'assist'], assist);
        state.set(['data', 'response'], response);
        state.set(['data', 'intervention'], intervention);
        return callback(null, guest);
      }
    );
  },
  del({ clients, callback }, { uuid }) {
    clients.api.guest.del({ params: { guest: uuid } }, (err) => {
      return callback(err);
    });
  },
};
