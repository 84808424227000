import React from 'react';

export const abstractPrinter = (maxLength = 50) => {
  return function AbstractPrinter({ item, itemKey }) {
    let abstract = item[itemKey];

    if (abstract && abstract.length > maxLength) {
      let index = abstract.lastIndexOf(' ', maxLength);
      if (index < 0) index = 50;

      abstract = abstract.substring(0, index);
    }

    return <span>{abstract}</span>;
  };
};
