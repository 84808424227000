import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t, LocaleDate } from 'zedevs-locales';

import { branch as actionsWrapper } from '../high-order/actions';

import Icon from '../elements/Icon';
import Toolbar from '../elements/calendar/Toolbar';
import Grid from '../elements/calendar/Grid';

function MyInterventions({
  actions,
  interventions = [],
  metadata: { view = 'week', date = Date.now() },
}) {
  return (
    <div className="interventions">
      <h1 className="row">
        <span className="col-10">{t('titles.my.interventions')}</span>
        <span className="title-icon col-2">
          <Icon name="intervention" />
        </span>
      </h1>
      <Toolbar date={date} view={view} />
      <Grid
        date={date}
        view={view}
        events={interventions.map((intervention) => ({
          id: intervention.uuid,
          extendedProps: intervention,
          title: intervention.assist.title,
          start: new LocaleDate(intervention.start),
          end: new LocaleDate(intervention.start).add(
            intervention.response.duration,
            'hour'
          ),
          classNames: ['event'],
        }))}
        eventClick={({ el, event: { extendedProps: props }, jsEvent }) => {
          jsEvent.stopPropagation();
          el.id = props.uuid;
          actions.navigation.tooltip({
            reference: props.uuid,
            component: 'event',
            props,
          });
        }}
      />
    </div>
  );
}
export const myinterventions = dataWrapper(
  {
    interventions: ['data', 'interventions'],
    metadata: ['navigation', 'page', 'metadata'],
  },
  actionsWrapper(['navigation'], MyInterventions)
);

myinterventions.header = true;
myinterventions.footer = true;
