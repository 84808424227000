import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';

import { t } from 'zedevs-locales';

import Guests from '../display/assist/Guests';
import ResponseList from '../display/response/List';
import ResponseView from '../display/response/View';
import InterventionView from '../display/intervention/View';

import Icon from '../elements/Icon';
import Navigation from '../elements/Navigation';
import { branch as actionsWrapper } from '../high-order/actions';

function AssistView({
  assist = {},
  plan = {},
  guests = [],
  responses = [],
  intervention = {},
  user,
  features,
  actions,
}) {
  const selectedResponse = responses.filter(
    (response) => response.uuid === intervention.response
  )[0];

  const publishable = assist.plan || features.publish;

  return (
    <div className="assist">
      <h1 className="row">
        <span className="col-10">
          {t('titles.view.assist')} ({t(['assist', 'steps', assist.step || 0])})
        </span>
        <span className="title-icon col-2">
          <Icon name="assist" />
        </span>
      </h1>
      <div className="row">
        <h2 className="col-12">{assist.title}</h2>
        <div className="tags col-12 col-sm-12 col-md-6 col-lg-9">
          {(assist.keywords || []).map((k) => (
            <span className="btn btn-sm btn-primary" key={k}>
              {k}
            </span>
          ))}
        </div>
        <div className="col-12">
          {t('fields.plan.input-label')}
          {t('commons.dots')}
          &nbsp;
          {plan.name || t('plans.none')}
        </div>
      </div>
      <div className="row my-3">
        {(assist.description || '').split('\n').map((text, index) => (
          <p className="col-12 my-0" key={`description-${index}`}>
            {text}
          </p>
        ))}
      </div>
      {assist.applicant === user && (
        <div>
          <div className="row justify-content-center">
            <div className="btn-group">
              {assist.step === 0 && (
                <button
                  className="btn btn-primary"
                  onClick={() =>
                    actions.navigation.open({
                      section: 'assist',
                      display: 'edit',
                      uuid: assist.uuid,
                    })
                  }
                >
                  {t('commons.edit')}
                  &nbsp;
                  <Icon name="edit" />
                </button>
              )}
              {assist.step === 0 && (
                <button
                  className={`btn btn-outline-${
                    publishable ? 'success' : 'secondary'
                  }`}
                  disabled={!publishable}
                  onClick={() =>
                    actions.assist.publish(
                      { assist: assist.uuid },
                      actions.toast.wrapper({
                        error: 'assist-publish-error',
                        success: 'assist-publish-success',
                      })
                    )
                  }
                >
                  {t('assist.publish')}
                  &nbsp;
                  <Icon name="publish" />
                </button>
              )}
              {assist.step === 1 && (
                <button
                  className="btn btn-outline-warning"
                  onClick={() =>
                    actions.assist.unpublish(
                      { assist: assist.uuid },
                      actions.toast.wrapper({
                        error: 'assist-unpublish-error',
                        success: 'assist-unpublish-success',
                      })
                    )
                  }
                >
                  {t('assist.unpublish')}
                  &nbsp;
                  <Icon name="unpublish" />
                </button>
              )}
              {assist.step < 2 && (
                <button
                  className="btn btn-outline-danger"
                  onClick={() =>
                    actions.assist.close(
                      { assist: assist.uuid },
                      actions.toast.wrapper({
                        error: 'assist-close-error',
                        success: 'assist-close-success',
                      })
                    )
                  }
                >
                  {t('assist.close')}
                  &nbsp;
                  <Icon name="cancel" />
                </button>
              )}
            </div>
          </div>
          {!assist.plan && !features.publish && (
            <div className="row justify-content-center">
              {t('assist.no-plan')}
            </div>
          )}
          <Guests assist={assist} guests={guests} />
          {assist.step === 1 && responses.length > 0 && <ResponseList />}
          {assist.step === 2 && (
            <div className="container">
              <ResponseView response={selectedResponse} />
              <InterventionView
                intervention={intervention}
                response={selectedResponse}
                assist={assist}
              />
            </div>
          )}
        </div>
      )}
      {assist.applicant !== user && (
        <div className="row justify-content-center">
          {responses.length ? (
            <div className="container">
              <ResponseView
                response={responses[0]}
                displaySlots={intervention.response !== responses[0].uuid}
              />
              {intervention.response === responses[0].uuid && (
                <InterventionView
                  intervention={intervention}
                  response={selectedResponse}
                  assist={assist}
                />
              )}
            </div>
          ) : (
            <Navigation
              className="btn btn-primary"
              section="response"
              display="create"
              container="modal"
            >
              {t('commons.reply')}
              &nbsp;
              <Icon name="response" />
            </Navigation>
          )}
        </div>
      )}
    </div>
  );
}

export const assistview = dataWrapper(
  {
    user: ['data', 'user', 'uuid'],
    features: ['data', 'user', 'features'],
    assist: ['data', 'assist'],
    plan: ['data', 'plan'],
    guests: ['data', 'guests'],
    responses: ['data', 'responses'],
    intervention: ['data', 'intervention'],
  },
  actionsWrapper(['assist', 'navigation', 'toast'], AssistView)
);
assistview.header = true;
assistview.footer = true;
