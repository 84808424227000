import React, { useState } from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../high-order/actions';

import Logo from '../elements/Logo';

import Password from '../elements/Password';

function PasswordReset({ actions, hooks, token }) {
  const [passwordStatus, setPasswordStatus] = useState(null);

  const { onSubmit, addInputRef, submit, getData } = hooks.useForm({
    action: ({ password, repeat }, callback) => {
      if (password === repeat) {
        actions.profile.resetPassword({ token, password }, callback);
      }
    },
    error: 'reset-password-failed',
    success: 'reset-password-success',
    callback: (err) => {
      if (!err) actions.navigation.open();
    },
    button: {
      label: 'commons.save',
    },
  });

  return (
    <div className="register">
      <div className="mask">
        <div className="center">
          <Logo />
          <form
            className="form row justify-content-center"
            autoComplete="off"
            onSubmit={onSubmit}
          >
            <h4 className="title">{t('password-reset.title')}</h4>
            <Password
              label={t('fields.password.input-label')}
              status={passwordStatus}
              setStatus={setPasswordStatus}
              getData={getData}
              addInputRef={addInputRef}
            />
            <Password
              label={t('register.password-repeat')}
              status={passwordStatus}
              setStatus={setPasswordStatus}
              isRepeat
              getData={getData}
              addInputRef={addInputRef}
            />
            <div className="form-group submit">{submit}</div>
          </form>
        </div>
      </div>
    </div>
  );
}

export const passwordreset = dataWrapper(
  {
    locale: ['locale'],
    token: ['navigation', 'page', 'uuid'],
  },
  actionsWrapper(['profile', 'navigation'], PasswordReset)
);
passwordreset.header = false;
passwordreset.footer = true;
