import React from 'react';

import Icon from '../elements/Icon';

import { t } from 'zedevs-locales';

import List from '../display/assist/List';
import Navigation from '../elements/Navigation';

export function myassists() {
  return (
    <div className="assist">
      <h1 className="row">
        <span className="col-10">{t('titles.my.assists')}</span>
        <span className="title-icon col-2">
          <Icon name="assist" />
        </span>
      </h1>
      <div>
        <Navigation
          className="btn btn-primary"
          section="assist"
          display="create"
        >
          {t('commons.add')} <Icon name="add" />
        </Navigation>
      </div>
      <List />
    </div>
  );
}

myassists.header = true;
myassists.footer = true;
