import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';

import { branch as actionsWrapper } from './high-order/actions';
import * as modals from './modals';

import Icon from './elements/Icon';

class ModalContainer extends Component {
  constructor() {
    super();
    this.close = this.close.bind(this);
  }

  close() {
    const { actions } = this.props;
    actions.navigation.close();
  }

  render() {
    const { modal } = this.props;
    const Modal = modal in modals ? modals[modal] : false;

    if (!Modal) return null;

    return (
      <div className="modal fade show">
        <div className="modal-dialog">
          <a className="cross" onClick={this.close}>
            <Icon name="close" />
          </a>
          <div className="modal-content container">
            <Modal />
          </div>
        </div>
      </div>
    );
  }
}

export default branch(
  {
    locale: ['locale'],
    modal: ['modal'],
  },
  actionsWrapper(['navigation'], ModalContainer)
);
