import { transform } from 'lodash';

import logger from '../utils/logger';

import api from './clients/api';

/**
 * This wrapper will log any call to any service. Since djax-client export some
 * of its own helpers with _ prefixed keys, it will not wrap those keys.
 */
function wrap(name, obj, path = []) {
  return transform(obj, (res, value, key) => {
    if (key.match(/^_/)) {
      res[key] = value;
    } else if (typeof value === 'function') {
      res[key] = (...args) => {
        logger.info(
          null,
          ...[`SERVICE-${name}`, path.concat(key), ...args].filter(
            (v) => typeof v !== 'function'
          )
        );
        value.call(res, ...args);
      };
    } else if (typeof value === 'object') {
      res[key] = wrap(name, value, path.concat(key));
    } else {
      res[key] = value;
    }
  });
}

export default {
  api: wrap('api', api),
};
