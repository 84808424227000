import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';

import { branch as actionsWrapper } from '../high-order/actions';

import { t } from 'zedevs-locales';

import Icon from '../elements/Icon';

import MemberForm from '../display/organization/MemberForm';
import MemberList from '../display/organization/MemberList';

function MyColleagues({ actions, members = [], user }) {
  const roles = members.reduce((collect, m) => {
    collect[m.role] = collect[m.role] || [];
    collect[m.role].push(m);
    return collect;
  }, {});

  const readOnly = user.role !== 'admin';
  const grantMember = ({ uuid }) =>
    actions.navigation.setMetadata({
      metadata: { member: uuid },
    });

  return (
    <div className="organization">
      <h1 className="row">
        <span className="col-10">{t('titles.my.members')}</span>
        <span className="title-icon col-2">
          <Icon name="members" />
        </span>
      </h1>
      {readOnly || <MemberForm organization={user.organization} />}
      <MemberList
        role="admin"
        members={roles.admin}
        readOnly={readOnly}
        editCallback={grantMember}
      />
      <MemberList
        role="manager"
        members={roles.manager}
        readOnly={readOnly}
        editCallback={grantMember}
      />
      <MemberList
        role="member"
        members={roles.member}
        readOnly={readOnly}
        editCallback={grantMember}
      />
      {readOnly ? undefined : (
        <MemberList
          role="locked"
          members={roles.locked}
          readOnly={readOnly}
          editCallback={grantMember}
        />
      )}
    </div>
  );
}

export const mycolleagues = dataWrapper(
  {
    user: ['data', 'user'],
    members: ['data', 'members'],
  },
  actionsWrapper(['navigation'], MyColleagues)
);

mycolleagues.header = true;
mycolleagues.footer = true;
