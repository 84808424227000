import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../../high-order/actions';

import Icon from '../../elements/Icon';

import FormEntry from '../../elements/FormEntry';
import { commonSizes } from '../../../utils';

function MemberForm({ actions, hooks, current, members = [], organization }) {
  const currentData = members.filter(({ uuid }) => uuid === current)[0] || {};

  const mode = current ? 'grant' : 'invite';
  const { onSubmit, addInputRef, addFormRef, submit } = hooks.useForm({
    action: (data, callback) => {
      actions.organization[mode](
        {
          uuid: current,
          organization,
          role: current ? data.role : undefined,
          data: current ? undefined : data,
        },
        callback
      );
    },
    error: `colleague-${mode}-error`,
    success: `colleague-${mode}-success`,
    callback: () => actions.navigation.setMetadata(),
    button: {
      label: current ? 'commons.save' : 'commons.add',
      icon: current ? <Icon name="save" /> : <Icon name="userAdd" />,
    },
  });

  return (
    <form className="row" onSubmit={onSubmit} ref={addFormRef}>
      <FormEntry id="email" {...commonSizes.quadruple} label={null}>
        <input
          type="text"
          className="form-control"
          id="email"
          placeholder={t('fields.email.input-label')}
          defaultValue={current ? currentData.email : ''}
          disabled={!!current}
          ref={addInputRef}
        />
      </FormEntry>
      <FormEntry id="phone" {...commonSizes.quadruple} label={null}>
        <input
          type="text"
          className="form-control"
          id="phone"
          placeholder={t('fields.phone.input-label')}
          defaultValue={current ? currentData.phone : ''}
          disabled={!!current}
          ref={addInputRef}
        />
      </FormEntry>
      <FormEntry id="phone" {...commonSizes.quadruple} label={null}>
        <select
          id="role"
          className="form-control"
          defaultValue={current ? currentData.phone : ''}
          ref={addInputRef}
        >
          <option value="member">{t('roles.member')}</option>
          <option value="manager">{t('roles.manager')}</option>
          <option value="admin">{t('roles.admin')}</option>
        </select>
      </FormEntry>
      <FormEntry id="submit" {...commonSizes.quadruple} label={null}>
        <div className="btn-group input-group" role="group">
          {submit}
          <button
            type="reset"
            className="btn btn-secondary form-control"
            onClick={() => actions.navigation.setMetadata()}
          >
            <Icon name="cancel" />
          </button>
        </div>
      </FormEntry>
    </form>
  );
}

export default dataWrapper(
  {
    members: ['data', 'members'],
    current: ['navigation', 'page', 'metadata', 'member'],
  },
  actionsWrapper(['navigation', 'organization'], MemberForm)
);
