import React from 'react';
import { t } from 'zedevs-locales';

const Home = () => (
  <div className="home container">
    <div className="row">
      <div className="col-sm-12 col-lg-6">
        <p>{t('home.p1')}</p>
        <p>{t('home.p2')}</p>
        <p>{t('home.p3')}</p>
        <p>{t('home.p4')}</p>
        <p>{t('home.p5')}</p>
        <p>{t('home.p6')}</p>
      </div>
      <div className=" col-sm-12 col-lg-6">
        <div className="video">
          <iframe
            src="https://www.youtube-nocookie.com/embed/SR4nwv8Zw38?mute=1"
            frameBorder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  </div>
);
export const home = Home;

home.header = true;
home.footer = true;
