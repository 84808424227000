import React from 'react';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../high-order/actions';

import Logo from '../elements/Logo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';

import Navigation from '../elements/Navigation';

function Login({ actions, hooks }) {
  const { onSubmit, addInputRef, submit } = hooks.useForm({
    action: actions.session.login,
    types: {
      email: 'string',
      password: 'string',
    },
    error: 'authentication-failed',
    button: {
      label: 'login.connect',
    },
  });

  return (
    <div className="login">
      <div className="mask">
        <div className="center">
          <Logo />
          <form className="form" autoComplete="off" onSubmit={onSubmit}>
            <h4 className="title">{t('login.connection')}</h4>
            <div className="form-group">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                </div>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder={t('fields.email.input-label')}
                  ref={addInputRef}
                />
              </div>
            </div>
            <div className="form-group">
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text">
                    <FontAwesomeIcon icon={faLock} />
                  </div>
                </div>
                <input
                  type="password"
                  name="password"
                  className="form-control"
                  placeholder={t('fields.password.input-label')}
                  ref={addInputRef}
                />
              </div>
              <small className="form-text">
                <Navigation
                  section="password"
                  className="text-muted"
                  display="forgot"
                >
                  {t('login.forgotten-password')}
                </Navigation>
              </small>
            </div>
            <div className="form-group submit">{submit}</div>
          </form>
        </div>
      </div>
    </div>
  );
}

export const login = actionsWrapper(['session'], Login);
login.header = false;
login.footer = true;
