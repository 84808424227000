import { LocaleDate } from 'zedevs-locales';

import {
  faCalendarAlt,
  faCalendarWeek,
  faCalendarDay,
} from '@fortawesome/free-solid-svg-icons';

export const views = {
  month: {
    icon: faCalendarAlt,
    format(date) {
      return new LocaleDate(date).format('MY');
    },
    grid: 'dayGridMonth',
  },
  week: {
    icon: faCalendarWeek,
    format(date) {
      const handle = new LocaleDate(date);
      return `${handle
        .clone()
        .startOf('week')
        .format('L')} - ${handle.clone().endOf('week').format('L')}`;
    },
    grid: 'timeGridWeek',
  },
  day: {
    icon: faCalendarDay,
    format(date) {
      return new LocaleDate(date).format('LL');
    },
    grid: 'timeGridDay',
  },
};
