import typology from 'typology';

export const types = new typology();

types.add('string[]', ['string']);
types.add('organizationMetadata', {
  features: {
    publish: 'boolean',
    reply: 'boolean',
  },
});
