import React, { useEffect } from 'react';
import { t, LocaleDate } from 'zedevs-locales';

import { branch as actionsWrapper } from '../high-order/actions';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faCircleNotch,
  faDoorClosed,
} from '@fortawesome/free-solid-svg-icons';

import Score from '../elements/Score';

export default actionsWrapper(
  ['intervention'],
  function Visio({
    actions,
    hooks,
    uuid,
    title,
    name,
    start,
    end,
    role,
    note,
    closedTime,
  }) {
    const startTime = new LocaleDate(start);
    const endTime = new LocaleDate(end);
    const ready = startTime.isBefore();
    const closed = endTime.isBefore() || !!closedTime;

    hooks.useScript('https://meet.jit.si/external_api.js');
    hooks.useTime({ shouldStop: () => endTime.isBefore() });

    const avatar = `${window.location.origin}/images/avatar.png`;

    const jitsi = hooks.useJitsi({
      roomName: uuid,
      parentNode: 'jitsi-container',
      subject: title,
      configOverwrite: {
        startWithAudioMuted: true,
        defaultLanguage: 'fr',
        prejoinPageEnabled: false,
        enableWelcomePage: false,
      },
      interfaceConfigOverwrite: {
        APP_NAME: 'ZeDevs',
        DEFAULT_BACKGROUND: '#37474f',
        lang: 'fr',
        DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        HIDE_INVITE_MORE_HEADER: true,
        MOBILE_APP_PROMO: false,
        SHOW_CHROME_EXTENSION_BANNER: false,
        TOOLBAR_BUTTONS: [
          'chat',
          'desktop',
          'camera',
          'microphone',
          'fullscreen',
          'settings',
        ],
        RANDOM_AVATAR_URL_PREFIX: avatar,
      },
    });

    useEffect(() => {
      if (jitsi) {
        jitsi.on('videoConferenceJoined', () => {
          jitsi.executeCommand('displayName', name);
        });

        jitsi.on('participantLeft', () => actions.intervention.get({ uuid }));
        return () => jitsi.dispose();
      }
      return undefined;
    }, [ready, jitsi, closed]);

    return (
      <div className="visio">
        <div className="screen">
          {!ready && (
            <div className="will-start">
              <div className="icon">
                <FontAwesomeIcon icon={faClock} />
              </div>
              <div className="text">
                {t('visio.will-start', { time: startTime.from() })}
              </div>
            </div>
          )}
          {closed && (
            <div className="is-closed">
              <div className="icon">
                <FontAwesomeIcon icon={faDoorClosed} />
              </div>
              <div className="text">{t('visio.is-closed')}</div>
              {role !== 'guest' && (
                <div>
                  <div className="text">{t(['visio', 'note', role])}</div>
                  <Score
                    defaultValue={note}
                    onChange={(s) =>
                      actions.intervention.note({ uuid, note: s.value })
                    }
                  />
                </div>
              )}
            </div>
          )}
          {ready && !closed && (
            <div
              id="jitsi-container"
              style={{ display: ready ? 'block' : 'none' }}
            ></div>
          )}
          {ready && !closed && !jitsi && (
            <div className="loading">
              <div className="icon">
                <FontAwesomeIcon icon={faCircleNotch} />
              </div>
              <div className="text">{t('visio.script-loading')}</div>
            </div>
          )}
        </div>
        {role === 'applicant' && ready && !closed && (
          <div className="row justify-content-center">
            <button
              className="btn btn-outline-danger"
              onClick={() => actions.intervention.close({ uuid })}
            >
              {t('visio.close')}
            </button>
          </div>
        )}
      </div>
    );
  }
);
