export default {
  setLanguage({ state, callback }, { locale }) {
    state.set(['locale'], locale);
    callback();
  },
  open(
    { state, callback },
    {
      section = null,
      uuid = null,
      display = null,
      metadata = {},
      container = 'page',
    }
  ) {
    state.set(['navigation', container], { section, uuid, display, metadata });
    callback();
  },
  setMetadata({ state, callback }, { metadata = {}, container = 'page' }) {
    state.set(['navigation', container, 'metadata'], metadata);
    callback();
  },
  close({ actions, callback }) {
    actions.navigation.open({ container: 'modal' });
    callback();
  },
  reload({ state, callback }, { container = 'page' }) {
    state.set(['navigation', container, 'timestamp'], Date.now());
    callback();
  },
  tooltip(
    { state, actions, callback },
    { reference = null, component = null, props = {} }
  ) {
    state.set(['navigation', 'tooltip'], { reference, component, props });
    document.addEventListener('click', actions.navigation.tooltip);
    callback();
  },
};
