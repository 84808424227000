import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t } from 'zedevs-locales';

import Icon from '../elements/Icon';

import ProfileSelection from '../elements/ProfileSelection';
import ProviderList from '../display/provider/List';
import ProviderView from '../display/provider/View';

function PlanWiew({ plan = {} }) {
  const { providers = [], applicants = [], isClient } = plan;

  return (
    <div className="plan container">
      <div className="row">
        <div className="container">
          <h1 className="row">
            <span className="col-10">{plan.name}</span>
            <span className="title-icon col-2">
              <Icon name="plan" />
            </span>
          </h1>
          <div className="row">
            <p className="col-12">{plan.description}</p>
          </div>
        </div>
        {plan.isClient && (
          <div className="container">
            <h2>{t('plans.applicants')}</h2>
            <ProfileSelection
              emptyText={t('plans.no-applicants')}
              readOnly
              selection={applicants
                .filter(({ uuid }) => uuid)
                .map(({ uuid, profile: { firstname, lastname, email } }) => ({
                  key: uuid,
                  label: `${firstname} ${lastname} (${email})`,
                }))}
            />
          </div>
        )}
        <div className="container">
          <h2>
            {t(
              providers.length > 1
                ? 'titles.list.providers'
                : 'titles.view.provider'
            )}
          </h2>
          {providers.length > 1 ? (
            <ProviderList
              providers={providers.map(({ uuid, organization, expiresAt }) => ({
                uuid,
                name: organization.name,
                expiresAt,
              }))}
            />
          ) : (
            <ProviderView
              provider={providers[0]}
              level="3"
              isClient={isClient}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export const planview = dataWrapper(
  {
    page: ['navigation', 'page'],
    plan: ['data', 'plan'],
    candidates: ['data', 'applicantCandidates'],
  },
  PlanWiew
);

planview.header = true;
planview.footer = true;
