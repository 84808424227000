import React from 'react';

export const numberPrinter = ({ unit = '', precision = 0 } = {}) => {
  return function NumberPrinter({ item, itemKey }) {
    let value = item[itemKey];

    if (value === undefined) return <span></span>;

    if (value && precision) {
      value =
        Math.round(value * Math.pow(10, precision)) / Math.pow(10, precision);
    }
    return (
      <span>
        {value} {unit}
      </span>
    );
  };
};
