import React from 'react';
import { t } from 'zedevs-locales';

import Icon from '../elements/Icon';

import List from '../display/assist/List';
import AssistSearch from '../display/assist/Search';

export const assistlist = function AssistList() {
  return (
    <div className="assist">
      <h1 className="row">
        <span className="col-10">{t('titles.search.assists')}</span>
        <span className="title-icon col-2">
          <Icon name="assist" />
        </span>
      </h1>
      <AssistSearch />
      <List />
    </div>
  );
};

assistlist.header = true;
assistlist.footer = true;
