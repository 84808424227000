import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';

import { branch as actionsWrapper } from '../high-order/actions';

import DeleteModal from '../elements/DeleteModal';
import actions from '../../core/actions';

function GuestDelete({ guest = {} }) {
  return (
    <DeleteModal
      type="guests"
      item={guest.email}
      callback={() => {
        actions.guest.del(
          { uuid: guest.uuid },
          actions.toast.wrapper({
            error: 'guest-delete-error',
            success: 'guest-delete-success',
            callback() {
              actions.navigation.close();
              actions.navigation.reload();
            },
          })
        );
      }}
    />
  );
}

export const guestdelete = dataWrapper(
  {
    guest: ['data', 'guest'],
  },
  actionsWrapper(['guest', 'toast', 'navigation'], GuestDelete)
);
