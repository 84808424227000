import Client from 'djax-client';

const client = new Client({
  settings: {
    baseUrl: '/api',
  },

  defaults: {
    headers: {
      'Cache-Control': 'no-cache, no-store, must-revalidate',
      Pragma: 'no-cache',
      Expires: '0',
    },
    contentType: 'application/json',
    dataType: 'json',
  },

  services: {
    session: {
      get: {
        url: '/session',
      },

      login: {
        url: '/login',
        method: 'POST',
      },

      logout: {
        url: '/logout',
        method: 'POST',
      },
    },

    password: {
      createToken: {
        url: '/password',
        method: 'POST',
      },
      checkToken: {
        url: '/password/:token',
        method: 'GET',
      },
      useToken: {
        url: '/password/:token',
        method: 'POST',
      },
    },

    profile: {
      search: {
        url: '/profile/search',
        method: 'POST',
      },

      get: {
        url: '/profile/:profile',
        method: 'GET',
      },

      update: {
        url: '/profile/:profile',
        method: 'POST',
      },

      reinvite: {
        url: '/profile/:profile/reinvite',
        method: 'POST',
      },

      getSkills: {
        url: '/profile/:profile/skills',
        method: 'GET',
      },

      addSkill: {
        url: '/profile/:profile/skill',
        method: 'POST',
      },

      updateSkill: {
        url: '/profile/:profile/skill/:uuid',
        method: 'POST',
      },

      certifySkill: {
        url: '/profile/:profile/skill/:uuid/certification',
        method: 'POST',
      },

      deleteSkill: {
        url: '/profile/:profile/skill/:uuid',
        method: 'DELETE',
      },

      getRegistration: {
        url: '/registration/:profile',
        method: 'GET',
      },

      setRegistration: {
        url: '/registration/:profile',
        method: 'POST',
      },

      getAssists: {
        url: '/profile/:profile/assists',
        method: 'GET',
      },

      getResponses: {
        url: '/profile/:profile/responses',
        method: 'GET',
      },

      getInterventions: {
        url: '/profile/:profile/interventions',
        method: 'GET',
        contentType: 'multipart/form-data',
      },

      getAvailabilities: {
        url: '/profile/:profile/availability',
        method: 'GET',
      },

      setAvailabilities: {
        url: '/profile/:profile/availability',
        method: 'POST',
      },

      getPlans: {
        url: '/profile/:profile/plans',
        method: 'GET',
      },
    },

    skills: {
      get: {
        url: '/skills/:skill',
        method: 'GET',
      },
      list: {
        url: '/skills',
        method: 'GET',
        contentType: 'multipart/form-data',
      },
      autocomplete: {
        url: '/skills/autocomplete',
        method: 'GET',
        contentType: 'multipart/form-data',
      },
      create: {
        url: '/skills',
        method: 'POST',
      },
      update: {
        url: '/skills/:skill',
        method: 'POST',
      },
      delete: {
        url: '/skills/:skill',
        method: 'DELETE',
      },
    },

    organization: {
      create: {
        url: '/organization/create',
        method: 'POST',
      },
      list: {
        url: '/organization/list',
        method: 'GET',
      },
      get: {
        url: '/organization/:organization',
        method: 'GET',
      },
      members: {
        url: '/organization/:organization/members',
        method: 'GET',
      },
      update: {
        url: '/organization/:organization',
        method: 'POST',
      },
      invite: {
        url: '/organization/:organization/invite',
        method: 'POST',
      },
      grant: {
        url: '/organization/:organization/grant/:uuid',
        method: 'POST',
      },
      createPlan: {
        url: '/organization/:organization/plan',
        method: 'POST',
      },
      plans: {
        url: '/organization/:organization/plans',
        method: 'GET',
      },
    },

    plan: {
      get: {
        url: '/plan/:plan',
        method: 'GET',
      },
      update: {
        url: '/plan/:plan',
        method: 'POST',
      },
      addApplicant: {
        url: '/plan/:plan/applicants',
        method: 'POST',
      },
      removeApplicant: {
        url: '/plan/:plan/applicants/:applicant',
        method: 'DELETE',
      },
      getCandidates: {
        url: '/plan/:plan/candidates',
        method: 'GET',
      },
      addProvider: {
        url: '/plan/:plan/providers',
        method: 'POST',
      },
      addCredit: {
        url: '/plan/:plan/credit',
        method: 'POST',
      },
    },

    provider: {
      get: {
        url: '/provider/:provider',
        method: 'GET',
      },
      getCandidates: {
        url: '/provider/:provider/candidates',
        method: 'GET',
      },
      addExpert: {
        url: '/provider/:provider/experts',
        method: 'POST',
      },
      removeExpert: {
        url: '/provider/:provider/experts/:expert',
        method: 'DELETE',
      },
      update: {
        url: '/provider/:provider',
        method: 'POST',
      },
    },

    assist: {
      create: {
        url: '/assist',
        method: 'POST',
      },

      list: {
        url: '/assist',
        method: 'GET',
        contentType: 'multipart/form-data',
      },

      get: {
        url: '/assist/:assist',
        method: 'GET',
      },

      update: {
        url: '/assist/:assist',
        method: 'POST',
      },

      getPlan: {
        url: '/assist/:assist/plan',
        method: 'GET',
      },

      getGuests: {
        url: '/assist/:assist/guests',
        method: 'GET',
      },

      getResponses: {
        url: '/assist/:assist/responses',
        method: 'GET',
      },

      getIntervention: {
        url: '/assist/:assist/intervention',
        method: 'GET',
      },

      respond: {
        url: '/assist/:assist/response',
        method: 'POST',
      },

      setStep: {
        url: '/assist/:assist/step',
        method: 'POST',
      },

      addGuest: {
        url: '/assist/:assist/guests',
        method: 'POST',
      },
    },

    response: {
      get: {
        url: '/response/:response',
        method: 'GET',
      },
      reserve: {
        url: '/response/:response/intervention',
        method: 'POST',
      },
    },

    intervention: {
      get: {
        url: '/intervention/:intervention',
        method: 'GET',
      },
      note: {
        url: '/intervention/:intervention/note',
        method: 'POST',
      },
      close: {
        url: '/intervention/:intervention/close',
        method: 'POST',
      },
    },

    guest: {
      get: {
        url: '/guest/:guest',
        method: 'GET',
      },
      del: {
        url: '/guest/:guest',
        method: 'DELETE',
      },
    },
  },
});

export default client;
