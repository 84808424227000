import React from 'react';
import { t } from 'zedevs-locales';

import Navigation from '../elements/Navigation';
import Icon from '../elements/Icon';

export function event({ uuid, assist }) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">{assist.title}</div>
      </div>
      <div className="row mt-2">
        <Navigation
          className="btn btn-sm btn-primary col-12"
          section="assist"
          display="view"
          uuid={assist.uuid}
        >
          {t('assist.open')}
          &nbsp;
          <Icon name="assist" />
        </Navigation>
      </div>
      <div className="row mt-2">
        <Navigation
          className="btn btn-sm btn-primary col-12"
          section="intervention"
          display="view"
          uuid={uuid}
        >
          {t('intervention.connect')}
          &nbsp;
          <Icon name="intervention" />
        </Navigation>
      </div>
    </div>
  );
}
