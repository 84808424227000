import React, { Component } from 'react';

import { t } from 'zedevs-locales';
import { branch as actionsWrapper } from '../high-order/actions';

import Autocompleted from './Autocompleted';

function didItMatch(a, b) {
  return a.length === b.length && a.every((value) => b.includes(value));
}

class Tags extends Component {
  constructor({ defaultValue = [] }) {
    super();

    this.state = { tags: [...defaultValue] };
    this.add = this.add.bind(this);
    this.del = this.del.bind(this);
  }

  componentDidUpdate(
    { defaultValue: previousValue = [] },
    { tags: previousTags }
  ) {
    const { defaultValue: currentValue = [], onChange = () => {} } = this.props;
    const { tags = [] } = this.state;

    if (
      !didItMatch(currentValue, previousValue) &&
      didItMatch(tags, previousValue)
    ) {
      this.setState({ tags: [...currentValue] });
    }

    if (!didItMatch(tags, previousTags)) {
      onChange(this);
    }
  }

  add(value) {
    const { tags } = this.state;
    if (tags.includes(value) || !value) return undefined;
    return this.setState({ tags: [...tags, value] });
  }

  del(value) {
    const { tags } = this.state;
    this.setState({ tags: tags.filter((tag) => tag !== value) });
  }

  get value() {
    const { tags } = this.state;
    return tags.length ? tags : undefined;
  }

  get id() {
    const { id } = this.props;
    return id;
  }

  render() {
    const { tags } = this.state;
    return (
      <div className="tags">
        <Autocompleted name="tag" onEnter={(value) => this.add(value)} />
        <small className="form-text text-muted">
          {t('fields.keywords.help')}
        </small>
        <div className="tag-list">
          {tags.map((tag) => (
            <button
              key={tag}
              className="btn btn-sm btn-outline-dark"
              onClick={() => this.del(tag)}
            >
              {tag}
            </button>
          ))}
        </div>
      </div>
    );
  }
}

export default actionsWrapper(['skills'], Tags, true);
