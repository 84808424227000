import React, { Component } from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';

import { t } from 'zedevs-locales';

import Icon from '../elements/Icon';

import { branch as actionsWrapper } from '../high-order/actions';

class ProfileMenu extends Component {
  constructor() {
    super();

    this.state = { open: false };
    this.handleToggle = this.handleToggle.bind(this);
    this.logout = this.logout.bind(this);
    this.navigate = this.navigate.bind(this);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleToggle, false);
  }

  handleToggle(e) {
    const open = !this.state.open;
    this.setState({ open });

    if (open) {
      document.addEventListener('click', this.handleToggle, false);
    } else {
      document.removeEventListener('click', this.handleToggle, false);
    }
    e.preventDefault();
    e.stopPropagation();
  }

  logout() {
    const { actions } = this.props;
    actions.session.logout();
  }

  navigate({ section, display }) {
    const { actions } = this.props;
    actions.navigation.open({ section, display });
  }

  isActive({ section, display }) {
    const { page } = this.props;
    return section === page.section && display === page.display;
  }

  renderLink({ section, display, title }) {
    const classNames = [
      'dropdown-item',
      this.isActive({ section, display }) ? 'active' : '',
    ].join(' ');
    return (
      <a
        className={classNames}
        href="#"
        onClick={() => this.navigate({ section, display })}
      >
        {t(['titles', section, title || display])}
      </a>
    );
  }

  render() {
    const { active, user = {} } = this.props;
    const { open } = this.state;

    const show = open ? 'show' : '';

    const classNames = [
      'profile-menu',
      'dropdown',
      show,
      active ? 'active' : '',
    ].join(' ');

    return (
      <div className={classNames}>
        <a
          className="dropdown-toggle"
          role="button"
          onClick={this.handleToggle}
          href="#"
        >
          <span className="user-name">
            <div className="user-firstname">{user.firstname || 'John'}</div>
            <div className="user-lastname">{user.lastname || 'Doe'}</div>
          </span>
          <span className="caret"></span>
        </a>
        <div className={`dropdown-menu ${show} dropdown-menu-right`}>
          {this.renderLink({ section: 'my', display: 'profile' })}
          {this.renderLink({ section: 'my', display: 'skills' })}
          {this.renderLink({ section: 'my', display: 'availabilities' })}
          <div className="dropdown-divider"></div>
          {this.renderLink({ section: 'my', display: 'assists' })}
          {this.renderLink({ section: 'my', display: 'responses' })}
          {this.renderLink({ section: 'my', display: 'interventions' })}
          {user.organization ? (
            <div>
              <div className="dropdown-divider"></div>
              {this.renderLink({ section: 'my', display: 'organization' })}
              {this.renderLink({
                section: 'my',
                display: 'colleagues',
                title: 'members',
              })}
              {this.renderLink({ section: 'my', display: 'plans' })}
            </div>
          ) : undefined}
          <div className="dropdown-divider"></div>
          <a className="dropdown-item" href="#" onClick={this.logout}>
            <Icon name="logout" />
            <span> {t('commons.logout')}</span>
          </a>
        </div>
      </div>
    );
  }
}

export default dataWrapper(
  {
    user: ['data', 'user'],
    page: ['navigation', 'page'],
  },
  actionsWrapper(['navigation', 'session'], ProfileMenu)
);
