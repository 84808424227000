function isLuhn(value) {
  return (
    Array.from(value)
      .reverse()
      .map((number, index) => number * (1 + (index % 2)))
      .map((number) => (number > 9 ? number - 9 : number))
      .reduce((sum, number) => sum + number, 0) %
      10 ===
    0
  );
}

export function isSIREN(value) {
  const data = ('' + value).replace(/[^\d]/g, '');
  if (isNaN(parseInt(data, 10))) return false;
  if (data.length !== 9) return false;
  return isLuhn(data);
}
