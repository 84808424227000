import React, { useEffect, useState } from 'react';
import { branch } from 'baobab-react/higher-order';

import * as tooltips from './tooltips';

function TooltipContainer({ tooltip }) {
  const [css, setCss] = useState({});
  const { reference, component, props } = tooltip;

  const update = () => {
    let element = document.getElementById(reference);

    if (!element) return;

    let { top, left, bottom, height, width } = element.getBoundingClientRect();
    bottom = window.innerHeight - bottom;

    const style = { left: `${left + width / 2}px` };

    if (top > bottom) {
      style.bottom = `${bottom + height}px`;
    } else {
      style.top = `${top + height}px`;
    }

    setCss(style);
  };

  useEffect(() => {
    update();
    document.addEventListener('scroll', update, true);
    window.addEventListener('resize', update);
    return () => {
      document.removeEventListener('scroll', update);
      window.removeEventListener('resize', update);
    };
  }, [tooltip]);

  const Tooltip = component in tooltips ? tooltips[component] : false;

  if (!Tooltip) return null;

  return (
    <div
      className={['tooltip', 'show', css.top ? 'bottom' : 'top'].join(' ')}
      style={css}
    >
      <Tooltip {...props} />
    </div>
  );
}

export default branch({ tooltip: ['navigation', 'tooltip'] }, TooltipContainer);
