import React from 'react';
import { t } from 'zedevs-locales';

import FormEntry from '../../elements/FormEntry';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLinkedin,
  faGithub,
  faGitlab,
} from '@fortawesome/free-brands-svg-icons';

export default function SocialMedia({ metadata, addInputRef }) {
  return (
    <div className="personnal-information">
      <h2 className="col-12">{t('profile.social-media')}</h2>
      <FormEntry id="linkedin" label={null}>
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <FontAwesomeIcon icon={faLinkedin} />
            </div>
            <div className="input-group-text">https://www.linkedin.com/in/</div>
          </div>
          <input
            type="text"
            className="form-control"
            id="linkedin"
            placeholder={t('profile.linkedin')}
            defaultValue={metadata.linkedin}
            ref={addInputRef}
          />
        </div>
      </FormEntry>
      <FormEntry id="github" label={null}>
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <FontAwesomeIcon icon={faGithub} />
            </div>
            <div className="input-group-text">https://github.com/</div>
          </div>
          <input
            type="text"
            className="form-control"
            id="github"
            placeholder={t('profile.github')}
            defaultValue={metadata.github}
            ref={addInputRef}
          />
        </div>
      </FormEntry>
      <FormEntry id="gitlab" label={null}>
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">
              <FontAwesomeIcon icon={faGitlab} />
            </div>
            <div className="input-group-text">https://gitlab.com/</div>
          </div>
          <input
            type="text"
            className="form-control"
            id="gitlab"
            placeholder={t('profile.gitlab')}
            defaultValue={metadata.gitlab}
            ref={addInputRef}
          />
        </div>
      </FormEntry>
    </div>
  );
}
