import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t } from 'zedevs-locales';

import ProfileForm from '../display/profile/Form';
import SkillsForm from '../display/skills/Form';

export const adminmember = dataWrapper(
  {
    member: ['data', 'profile'],
    currentSkill: ['navigation', 'page', 'metadata', 'skill'],
  },
  ({ currentSkill, member }) => (
    <div className="profile">
      <ProfileForm owner member={member} title={t('titles.view.profile')} />
      <SkillsForm member={member} current={currentSkill} />
    </div>
  )
);

adminmember.header = true;
adminmember.footer = true;
