import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';

import { t } from 'zedevs-locales';
import { branch as actionsWrapper } from '../high-order/actions';

import Icon from '../elements/Icon';

import { navigatePrinter } from '../elements/printers';
import { Table, Column } from '../elements/table';
import Navigation from '../elements/Navigation';

function AdminOrganizations({ organizations = [] }) {
  return (
    <div className="organization">
      <h1 className="row">
        <span className="col-10">{t('titles.list.organizations')}</span>
        <span className="title-icon col-2">
          <Icon name="organization" />
        </span>
      </h1>
      <div>
        <Navigation
          type="button"
          className="btn btn-primary"
          section="organization"
          display="create"
          container="modal"
        >
          {t('commons.add')} <Icon name="add" />
        </Navigation>
      </div>
      <Table items={organizations}>
        <Column
          itemKey="name"
          printer={navigatePrinter({
            section: 'admin',
            display: 'organization',
          })}
        />
        <Column itemKey="siren" />
        <Column itemKey="city" />
        <Column itemKey="country" />
      </Table>
    </div>
  );
}

export const adminorganizations = dataWrapper(
  {
    organizations: ['data', 'organizations'],
  },
  actionsWrapper(['organization', 'navigation', 'toast'], AdminOrganizations)
);

adminorganizations.header = true;
adminorganizations.footer = true;
