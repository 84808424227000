import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t, LocaleDate } from 'zedevs-locales';

import { commonSizes } from '../../../utils';

import Icon from '../../elements/Icon';

import FormEntry from '../../elements/FormEntry';
import Navigation from '../../elements/Navigation';
import Visio from '../../elements/Visio';

export default dataWrapper(
  { user: ['data', 'user'] },
  function InterventionView({
    user = {},
    intervention = {},
    response = {},
    assist = {},
    mainPage,
  }) {
    let role = 'guest';

    if (user.uuid === assist.applicant) role = 'applicant';
    else if (user.uuid === response.expert) 'expert';

    return (
      <div className="intervention">
        <h1 className="row">
          <span className="col-10">{t('titles.view.intervention')}</span>
          <span className="title-icon col-2">
            <Icon name="intervention" />
          </span>
        </h1>
        <div className="row scrollable">
          <div className="container">
            <div className="row justify-content-center">
              <FormEntry
                label={t('intervention.datetime')}
                {...commonSizes.simple}
              >
                <div className="form-control">
                  {intervention.start &&
                    new LocaleDate(intervention.start).format('LLLL')}
                </div>
              </FormEntry>
              {mainPage || (
                <Navigation
                  className="btn btn-primary mb-5"
                  section="intervention"
                  display="view"
                  uuid={intervention.uuid}
                >
                  {t('intervention.connect')}&nbsp;
                  <Icon name="visio" />
                </Navigation>
              )}
              {mainPage && assist.title && (
                <Visio
                  uuid={intervention.uuid}
                  title={assist.title}
                  name={user.firstname}
                  start={intervention.start}
                  end={intervention.start + response.duration * 3600 * 1000}
                  role={role}
                  note={intervention[`${role}Note`]}
                  closedTime={intervention.closed}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
