import React, { Component } from 'react';
import { times } from 'lodash';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as fullStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons';

class Score extends Component {
  constructor({ defaultValue = undefined }) {
    super();

    this.state = { score: defaultValue };
    this.set = this.set.bind(this);
  }

  componentDidUpdate(
    { defaultValue: previousProp = undefined },
    { score: previousScore }
  ) {
    const {
      defaultValue: currentProp = undefined,
      onChange = () => {},
    } = this.props;
    const { score = undefined } = this.state;

    if (currentProp !== previousProp && score === previousProp) {
      this.setState({ score: currentProp });
    }

    if (score !== previousScore) {
      onChange(this);
    }
  }

  set(score) {
    return this.setState({ score });
  }

  get value() {
    const { score } = this.state;
    return score;
  }

  get id() {
    const { id } = this.props;
    return id;
  }

  render() {
    const { score } = this.state;

    return (
      <div className="form-control score">
        {times(5, (value) => {
          const full = value <= score;
          return (
            <a
              key={`value-${value}`}
              className={full ? 'full' : 'empty'}
              href="#"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                this.set(value);
              }}
            >
              <FontAwesomeIcon icon={full ? fullStar : emptyStar} />
            </a>
          );
        })}
      </div>
    );
  }
}

export default Score;
