import React from 'react';
import { find } from 'lodash';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../high-order/actions';

import Icon from '../elements/Icon';
import FormEntry from '../elements/FormEntry';
import Navigation from '../elements/Navigation';

import ProfileSelection from '../elements/ProfileSelection';
import ProviderList from '../display/provider/List';
import CreditHistory from '../display/plan/CreditHistory';

import { commonSizes } from '../../utils';

function AdminPlan({ actions, hooks, plan = {}, candidates = [], page }) {
  const { mode } = page.metadata;
  const current = mode === 'create' ? {} : plan;

  const { providers = [], applicants = [] } = current;

  const { onSubmit, addInputRef, submit } = hooks.useForm({
    action: (data, callback) =>
      actions.plan[mode](
        {
          uuid: current.uuid,
          data: {
            organization: mode === 'create' ? page.uuid : undefined,
            ...data,
          },
        },
        callback
      ),
    types: {
      name: 'string',
      description: 'string',
    },
    error: `plan-${mode}-error`,
    success: `plan-${mode}-success`,
    callback: (err, { uuid } = {}) =>
      actions.navigation.open({
        section: 'admin',
        display: 'planedit',
        uuid,
        metadata: { mode: 'update' },
      }),
    button: {
      label: 'commons.save',
    },
  });

  const selection = applicants
    .filter(({ uuid }) => uuid)
    .map(({ uuid, profile: { firstname, lastname, email } }) => ({
      key: uuid,
      label: `${firstname} ${lastname} (${email})`,
    }));
  const searchCallback = (value = '') =>
    candidates
      .filter(
        ({ uuid, lastname = '', firstname = '', email }) =>
          (lastname.toLowerCase().match(value.toLowerCase()) ||
            firstname.toLowerCase().match(value.toLowerCase()) ||
            email.toLowerCase().match(value.toLowerCase())) &&
          !find(
            applicants,
            (applicant) => applicant.profile.uuid === uuid && applicant.uuid
          )
      )
      .map(({ uuid, firstname, lastname, email }) => ({
        key: uuid,
        label: `${firstname} ${lastname} (${email})`,
      }));

  const addCallback = (applicant) => {
    actions.plan.addApplicant(
      { applicant, plan: plan.uuid },
      actions.toast.wrapper({
        error: 'applicant-add-error',
        success: 'applicant-add-success',
        callback: () => actions.navigation.reload(),
      })
    );
  };
  const removeCallback = (applicant) => {
    actions.plan.removeApplicant(
      { applicant, plan: plan.uuid },
      actions.toast.wrapper({
        error: 'applicant-remove-error',
        success: 'applicant-remove-success',
        callback: () => actions.navigation.reload(),
      })
    );
  };

  return (
    <div className="plan container">
      <div className="row">
        <div className="container">
          <h1 className="row">
            <span className="col-10">
              {t(['titles', mode === 'create' ? 'new' : 'view', 'plan'])}
            </span>
            <span className="title-icon col-2">
              <Icon name="plan" />
            </span>
          </h1>
          <form className="row" onSubmit={onSubmit}>
            <FormEntry id="name" {...commonSizes.simple}>
              <input
                type="text"
                className="form-control"
                id="name"
                ref={addInputRef}
                defaultValue={current.name}
              />
            </FormEntry>
            <FormEntry id="description">
              <textarea
                type="text"
                className="form-control"
                id="description"
                ref={addInputRef}
                defaultValue={current.description}
              />
            </FormEntry>
            <div className="form-group col-12 submit">{submit}</div>
          </form>
        </div>

        {mode === 'update' && (
          <div className="container">
            <CreditHistory
              plan={plan}
              callback={(amount) =>
                actions.plan.addCredit({ plan: plan.uuid, amount }, () =>
                  actions.navigation.reload()
                )
              }
            />
            <h2>{t('plans.applicants')}</h2>
            <ProfileSelection
              emptyText={t('plans.no-applicants')}
              selection={selection}
              searchCallback={searchCallback}
              addCallback={addCallback}
              removeCallback={removeCallback}
            />
            <h2>{t('titles.list.providers')}</h2>
            <div className="row ">
              <div className="col-12 text-right">
                <Navigation
                  className="btn btn-primary"
                  section="provider"
                  display="create"
                  container="modal"
                >
                  {t('commons.add')} <Icon name="add" />
                </Navigation>
              </div>
            </div>
            <ProviderList
              providers={(providers || []).map(
                ({ uuid, organization, expiresAt }) => ({
                  uuid,
                  name: organization.name,
                  expiresAt,
                })
              )}
              admin
            />
          </div>
        )}
      </div>
    </div>
  );
}

const adminplan = dataWrapper(
  {
    page: ['navigation', 'page'],
    plan: ['data', 'plan'],
    candidates: ['data', 'applicantCandidates'],
  },
  actionsWrapper(['plan', 'navigation', 'toast'], AdminPlan)
);

adminplan.header = true;
adminplan.footer = true;

export const adminplancreate = adminplan;
export const adminplanedit = adminplan;
