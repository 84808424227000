import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';

import View from '../display/intervention/View';

function InterventionGuest({ assist = {}, response = {}, intervention = {} }) {
  return (
    <div className="assist">
      <View
        intervention={intervention}
        response={response}
        assist={assist}
        mainPage
      />
    </div>
  );
}

export const interventionguest = dataWrapper(
  {
    intervention: ['data', 'intervention'],
    response: ['data', 'response'],
    assist: ['data', 'assist'],
    guest: ['data', 'guest'],
  },
  InterventionGuest
);
interventionguest.header = false;
interventionguest.footer = true;
