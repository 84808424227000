import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileAlt,
  faCalendarCheck,
  faPaperPlane,
  faAddressCard,
  faLifeRing,
  faThumbsUp,
  faThumbsDown,
  faTrashAlt,
  faCalendar,
} from '@fortawesome/free-regular-svg-icons';
import {
  faTimes,
  faDesktop,
  faSave,
  faUserPlus,
  faUserSlash,
  faTimesCircle,
  faPen,
  faCalendarTimes,
  faTools,
  faPlusCircle,
  faCheck,
  faExclamation,
  faEraser,
  faMedal,
  faSignOutAlt,
  faBuilding,
  faUserFriends,
  faBullhorn,
  faShare,
  faUndo,
  faBoxOpen,
} from '@fortawesome/free-solid-svg-icons';

const dictionary = {
  ok: faCheck,
  error: faExclamation,
  save: faSave,
  edit: faPen,
  cancel: faTimesCircle,
  close: faTimes,
  add: faPlusCircle,
  remove: faTrashAlt,
  clear: faEraser,
  up: faThumbsUp,
  down: faThumbsDown,
  resend: faPaperPlane,
  userAdd: faUserPlus,
  userBan: faUserSlash,
  display: faFileAlt,
  unpublish: faUndo,
  publish: faShare,
  logout: faSignOutAlt,

  organization: faBuilding,
  members: faUserFriends,
  guests: faUserFriends,
  profile: faAddressCard,
  availability: faCalendar,
  skill: faTools,
  plan: faBoxOpen,
  assist: faBullhorn,
  response: faLifeRing,
  slotRemove: faCalendarTimes,
  intervention: faCalendarCheck,
  visio: faDesktop,
  certified: faMedal,
};

export default function Icon({ name, ...props }) {
  return <FontAwesomeIcon icon={dictionary[name]} {...props} />;
}

/**

<Icon name="visio" />
import Icon from '../elements/Icon';


 */
