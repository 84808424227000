import { waterfall } from 'async';

let checkTimeout;

export default {
  setSession({ state, actions, callback }, { user }) {
    state.set('logged', true);
    state.set(['data', 'user'], user);

    if (checkTimeout) clearTimeout(checkTimeout);

    setTimeout(() => {
      actions.session.check();
    }, 10 * 1000); // 10s
    callback();
  },
  destroySession({ state, callback }) {
    state.set('logged', false);
    state.set(['data'], {});
    callback();
  },

  login({ clients, actions, callback }, { email, password }) {
    clients.api.session.login({ data: { email, password } }, (err, user) => {
      if (err || !user) {
        return actions.session.destroySession(() => callback(true));
      }
      return actions.session.setSession({ user }, callback);
    });
  },
  check({ clients, actions, callback }) {
    clients.api.session.get((err, user) => {
      if (err) return actions.session.destroySession(callback);
      if (!user) return actions.session.destroySession(callback);
      return actions.session.setSession({ user }, callback);
    });
  },
  logout({ clients, actions, callback }) {
    waterfall(
      [
        (cb) => clients.api.session.logout((err) => cb(err)),
        (cb) => actions.session.destroySession((err) => cb(err)),
        (cb) => actions.navigation.open(cb),
      ],
      callback
    );
  },
};
