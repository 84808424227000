import React from 'react';
import { omit, pick } from 'lodash';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../../high-order/actions';

import Icon from '../../elements/Icon';

import PersonnalInformation from './PersonnalInformation';
import SocialMedia from './SocialMedia';
import FormEntry from '../../elements/FormEntry';

import { commonSizes } from '../../../utils';

export default actionsWrapper(
  ['profile'],
  ({ actions, hooks, member = {}, owner, title }) => {
    const metadataFields = ['linkedin', 'github', 'gitlab'];

    const { onSubmit, addInputRef, submit } = hooks.useForm({
      action: (data, callback) =>
        actions.profile.update(
          {
            uuid: member.uuid,
            data: {
              ...omit(data, metadataFields),
              metadata: pick(data, metadataFields),
            },
          },
          callback
        ),
      error: 'profile-error',
      success: 'profile-success',
      button: {
        label: 'commons.save',
        icon: <Icon name="save" />,
      },
    });

    return (
      <form className="row" onSubmit={onSubmit}>
        <div className="container">
          <h1 className="row">
            <span className="col-10">{title}</span>
            <span className="title-icon col-2">
              <Icon name="profile" />
            </span>
          </h1>
          <PersonnalInformation member={member} addInputRef={addInputRef} />
          {owner && (
            <div className="personnal-information">
              <h2 className="col-12">{t('profile.role')}</h2>
              <FormEntry id="role" {...commonSizes.double}>
                <select
                  id="role"
                  className="form-control"
                  defaultValue={member.role}
                  key={member.uuid}
                  ref={addInputRef}
                >
                  <option value="member">{t('roles.member')}</option>
                  <option value="manager">{t('roles.manager')}</option>
                  <option value="admin">{t('roles.admin')}</option>
                </select>
              </FormEntry>
              <FormEntry id="isOwner" {...commonSizes.double}>
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="isOwner"
                    key={member.uuid}
                    defaultChecked={member.isOwner}
                    ref={addInputRef}
                  />
                  <label className="custom-control-label" htmlFor="isOwner" />
                </div>
              </FormEntry>
            </div>
          )}
          <SocialMedia
            metadata={member.metadata || {}}
            addInputRef={addInputRef}
          />
          <div className="row">
            <div className="form-group submit col-12">{submit}</div>
          </div>
        </div>
      </form>
    );
  }
);
