import React from 'react';
import { t, LocaleDate } from 'zedevs-locales';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarCheck,
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import { views } from './commons';

import { branch as actionsWrapper } from '../../high-order/actions';

export default actionsWrapper(
  ['navigation'],
  function Toolbar({ view, date, actions }) {
    return (
      <div className="row mb-3">
        <div className="col-12 col-sm-4 col-md-4 btn-group">
          <button
            className="btn btn-sm btn-secondary"
            onClick={() =>
              actions.navigation.setMetadata({
                metadata: {
                  view,
                  date: new LocaleDate(date).subtract(1, view).getTime(),
                },
              })
            }
          >
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <button
            className={`btn btn-sm btn-${
              new LocaleDate(date).isSame(view) ? 'primary' : 'secondary'
            }`}
            onClick={() =>
              actions.navigation.setMetadata({
                metadata: { view, date: Date.now() },
              })
            }
          >
            {t('commons.time.today')}&nbsp;
            <FontAwesomeIcon icon={faCalendarCheck} />
          </button>
          <button
            className="btn btn-sm btn-secondary"
            onClick={() =>
              actions.navigation.setMetadata({
                metadata: {
                  view,
                  date: new LocaleDate(date).add(1, view).getTime(),
                },
              })
            }
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
        <div className="col-12 col-sm-4 col-md-4 text-center">
          {views[view].format(date)}
        </div>
        <div className="col-12 col-sm-4 col-md-4 btn-group">
          {Object.keys(views).map((v) => (
            <button
              key={v}
              className={`btn btn-sm ${
                v === view ? 'btn-primary' : 'btn-secondary'
              }`}
              onClick={() =>
                actions.navigation.setMetadata({ metadata: { date, view: v } })
              }
            >
              {t(['commons', 'units', v], 1)}&nbsp;
              <FontAwesomeIcon icon={views[v].icon} />
            </button>
          ))}
        </div>
      </div>
    );
  }
);
