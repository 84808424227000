import React from 'react';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../../high-order/actions';

import FormEntry from '../../elements/FormEntry';
import Tags from '../../elements/Tags';
import { commonSizes } from '../../../utils';

function SkillSearch({ actions, hooks }) {
  const { onSubmit, addInputRef, submit } = hooks.useForm({
    action({ name, categories }, callback) {
      actions.navigation.setMetadata(
        {
          metadata: { skillFilters: { name, categories } },
        },
        callback
      );
    },
    button: {
      label: 'commons.search',
    },
  });

  return (
    <form className="row" onSubmit={onSubmit}>
      <h2 className="col-12">{t('commons.search')}</h2>
      <FormEntry id="name" {...commonSizes.simple}>
        <input
          type="text"
          className="form-control"
          id="name"
          ref={addInputRef}
        />
      </FormEntry>
      <FormEntry id="categories">
        <Tags id="categories" ref={addInputRef} />
      </FormEntry>
      <div className="form-group submit col-12">{submit}</div>
    </form>
  );
}

export default actionsWrapper(['navigation'], SkillSearch);
