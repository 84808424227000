import { waterfall } from 'async';
import { serviceToStateCallbackFactory } from '../utils';

export default {
  get({ state, clients, callback }, { uuid }) {
    clients.api.intervention.get(
      { params: { intervention: uuid } },
      serviceToStateCallbackFactory(state, ['data', 'intervention'], callback)
    );
  },
  load({ actions, callback }, { uuid }) {
    waterfall(
      [
        (cb) => actions.intervention.get({ uuid }, cb),
        (intervention, cb) =>
          actions.response.get({ uuid: intervention.response }, cb),
        (response, cb) => actions.assist.get({ uuid: response.assist }, cb),
      ],
      callback
    );
  },
  note({ state, clients, callback }, { uuid, note }) {
    clients.api.intervention.note(
      { params: { intervention: uuid }, data: { note } },
      serviceToStateCallbackFactory(state, ['data', 'intervention'], callback)
    );
  },
  close({ state, clients, callback }, { uuid }) {
    clients.api.intervention.close(
      { params: { intervention: uuid }, data: { time: new Date().valueOf() } },
      serviceToStateCallbackFactory(state, ['data', 'intervention'], callback)
    );
  },
};
