import React from 'react';

import { branch as dataWrapper } from 'baobab-react/higher-order';

import ResponseView from '../display/response/View';

export const responseview = dataWrapper(
  {
    response: ['data', 'response'],
  },
  ({ response }) => (
    <ResponseView response={response} allowReserve displaySlots />
  )
);
