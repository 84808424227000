import React, { useRef } from 'react';
import { t } from 'zedevs-locales';
import FormEntry from '../../elements/FormEntry';

import { dateFromNowPrinter, numberPrinter } from '../../elements/printers';
import { Table, Column } from '../../elements/table';

import { commonSizes } from '../../../utils';

export default function CreditList({ plan = {}, callback = () => {} }) {
  const amount = useRef();
  const { credit = {} } = plan;
  return (
    <div>
      <h2>{t('plans.credits')}</h2>
      <Table items={credit.history || []}>
        <Column itemKey="amount" printer={numberPrinter({ unit: '€ HT' })} />
        <Column
          title={t('fields.creationDate.input-label')}
          itemKey="created"
          printer={dateFromNowPrinter()}
        />
      </Table>
      <div>
        <FormEntry id="amount" {...commonSizes.simple}>
          <div className="input-group">
            <input
              type="number"
              className="form-control text-right"
              id="amount"
              ref={amount}
            />
            <div className="input-group-append">
              <div className="input-group-text">€ HT</div>
              <button
                className="btn btn-primary"
                onClick={() => {
                  callback(+amount.current.value);
                  amount.current.value = '';
                }}
              >
                {t('commons.add')}
              </button>
            </div>
          </div>
        </FormEntry>
      </div>
    </div>
  );
}
