import { useState, useEffect } from 'react';

export default () => ({
  domain = 'meet.jit.si',
  subject,
  parentNode,
  ...options
}) => {
  const [jitsi, setJitsi] = useState(null);

  useEffect(() => {
    if (window.JitsiMeetExternalAPI) {
      options.parentNode = document.getElementById(parentNode);

      if (options.parentNode) {
        const client = new window.JitsiMeetExternalAPI(domain, options);
        if (subject) {
          client.executeCommand('subject', subject);
        }
        setJitsi(client);
        return () => client.removeAllListeners() && client.dispose();
      }

      return undefined;
    }
    return undefined;
  }, [window.JitsiMeetExternalAPI]);

  return jitsi;
};
