import { useState, useEffect } from 'react';

export default () => (url) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const existingScript = Array.from(
      document.getElementsByTagName('script')
    ).reduce(
      (previous, element) => (element.src === url ? element : previous),
      null
    );
    const onload = (e) => {
      e.target.dataset.loaded = true;
      setLoaded(true);
    };

    if (existingScript) {
      if (existingScript.dataset.loaded) setLoaded(true);
      else existingScript.addEventListener('load', onload);
    } else {
      const script = document.createElement('script');
      script.src = url;
      document.body.appendChild(script);
      script.addEventListener('load', onload);
    }
  }, [url]);

  return loaded;
};
