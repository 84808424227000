import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';

import SkillsForm from '../display/skills/Form';

export const myskills = dataWrapper(
  {
    current: ['navigation', 'page', 'uuid'],
    member: ['data', 'user'],
  },
  ({ current, member }) => (
    <SkillsForm current={current} member={member} primary />
  )
);

myskills.header = true;
myskills.footer = true;
