import React from 'react';

import {
  navigatePrinter,
  abstractPrinter,
  creditPrinter,
  datePrinter,
} from '../../elements/printers';
import { Table, Column } from '../../elements/table';

function PlanList({ plans = [], admin = false }) {
  return (
    <div className="row">
      <Table items={plans}>
        <Column
          itemKey="name"
          width="20"
          printer={navigatePrinter({
            section: admin ? 'admin' : 'plan',
            display: admin ? 'planedit' : 'view',
            metadata: admin ? { mode: 'update' } : null,
          })}
        />
        <Column itemKey="description" width="40" printer={abstractPrinter()} />
        <Column itemKey="credit" width="20" printer={creditPrinter()} />
        <Column
          itemKey="expiresAt"
          width="20"
          printer={datePrinter({ format: 'LL' })}
        />
      </Table>
    </div>
  );
}

export default PlanList;
