import React from 'react';

export const creditPrinter = () => {
  return function CreditPrinter({ item, itemKey }) {
    const { amount, used = 0 } = item[itemKey] || {};

    if (!amount) return null;

    const pct = (used / amount) * 100;

    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="progress">
              <div
                className="progress-bar bg-secondary"
                style={{ width: `${pct}%` }}
              ></div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center">
            {used}/{amount} € HT
          </div>
        </div>
      </div>
    );
  };
};
