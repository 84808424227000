import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../../high-order/actions';

import Autocompleted from '../../elements/Autocompleted';

import Icon from '../../elements/Icon';

import { buttonGroupPrinter, medalPrinter } from '../../elements/printers';
import { Table, Column } from '../../elements/table';

function SkillForm({
  actions,
  hooks,
  current,
  skills = [],
  member,
  primary,
  page,
}) {
  const cancel = () => {
    if (primary) {
      const { section, display } = page;
      actions.navigation.open({ section, display });
    } else {
      actions.navigation.setMetadata();
    }
  };

  const mode = current ? 'update' : 'add';
  const { onSubmit, addInputRef, addFormRef, submit } = hooks.useForm({
    action: (data, callback) =>
      actions.profile[`${mode}Skill`](
        {
          uuid: current,
          profile: member.uuid,
          data,
        },
        callback
      ),
    error: `profile-skill-${mode}-error`,
    success: `profile-skill-${mode}-success`,
    callback: () => cancel(),
    button: {
      label: current ? 'commons.save' : 'commons.add',
      icon: current ? <Icon name="save" /> : <Icon name="add" />,
    },
  });

  const currentData = skills.filter(({ uuid }) => uuid === current)[0] || {};

  return (
    <form className="profile" ref={addFormRef} onSubmit={onSubmit}>
      <h1 className="row">
        <span className="col-10">
          {t(['titles', primary ? 'my' : 'list', 'skills'])}
        </span>
        <span className="title-icon col-2">
          <Icon name="skill" />
        </span>
      </h1>
      <div className="row">
        <Table items={skills}>
          <Column itemKey="certified" title="" printer={medalPrinter()} />
          <Column itemKey="name" title={t('fields.skill.input-label')}>
            <Autocompleted
              name="name"
              defaultValue={current ? currentData.name : ''}
              ref={current ? undefined : addInputRef}
              disabled={!!current}
            />
          </Column>
          <Column itemKey="experience" align="center">
            <div className="input-group">
              <input
                type="number"
                name="experience"
                className="form-control text-center"
                defaultValue={current ? currentData.experience : ''}
                ref={addInputRef}
              />
            </div>
          </Column>
          <Column
            itemKey="projects"
            align="center"
            title={t('fields.project-count.input-label')}
          >
            <div className="input-group">
              <input
                type="number"
                name="projects"
                className="form-control text-center"
                defaultValue={current ? currentData.projects : ''}
                ref={addInputRef}
              />
            </div>
          </Column>
          <Column
            title=""
            width="25"
            align="center"
            printer={buttonGroupPrinter([
              {
                label: <Icon name="edit" />,
                callback: ({ uuid }) => {
                  if (primary) {
                    const { section, display } = page;
                    actions.navigation.open({ section, display, uuid });
                  } else {
                    actions.navigation.setMetadata({
                      metadata: { skill: uuid },
                    });
                  }
                },
              },
              {
                label: <Icon name="up" />,
                color: 'outline-success',
                callback: ({ uuid, profile }) =>
                  actions.profile.certifySkill(
                    { uuid, profile },
                    actions.toast.wrapper({
                      error: 'profile-skill-certify-error',
                      success: 'profile-skill-certify-success',
                      callback: () => actions.navigation.reload(),
                    })
                  ),
                display({ certified }) {
                  return !primary && !certified;
                },
              },
              {
                label: <Icon name="down" />,
                color: 'outline-warning',
                callback: ({ uuid, profile }) =>
                  actions.profile.uncertifySkill(
                    { uuid, profile },
                    actions.toast.wrapper({
                      error: 'profile-skill-uncertify-error',
                      success: 'profile-skill-uncertify-success',
                      callback: () => actions.navigation.reload(),
                    })
                  ),
                display({ certified }) {
                  return !primary && certified;
                },
              },
              {
                label: <Icon name="remove" />,
                color: 'outline-danger',
                callback: ({ uuid }) =>
                  actions.profile.deleteSkill(
                    { uuid },
                    actions.toast.wrapper({
                      error: 'profile-skill-delete-error',
                      success: 'profile-skill-delete-success',
                      callback: () => cancel(),
                    })
                  ),
              },
            ])}
          >
            <div className="btn-group input-group" role="group">
              {submit}
              <button
                type="reset"
                className="btn btn-secondary form-control"
                onClick={cancel}
              >
                <Icon name="cancel" />
              </button>
            </div>
          </Column>
        </Table>
      </div>
    </form>
  );
}

export default dataWrapper(
  { skills: ['data', 'skills'], page: ['navigation', 'page'] },
  actionsWrapper(['navigation', 'profile', 'toast'], SkillForm)
);
