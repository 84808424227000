import React, { Component } from 'react';

import { t } from 'zedevs-locales';

import Icon from './Icon';
import FormEntry from './FormEntry';

class Password extends Component {
  constructor() {
    super();
    this.check = this.check.bind(this);
  }

  check() {
    const { setStatus, getData } = this.props;
    const values = getData();

    if (!values.password) return setStatus(null);
    if (values.password.length < 12) return setStatus('length');
    if (values.repeat && values.password !== values.repeat) {
      return setStatus('identical');
    }

    if (values.repeat && values.password) return setStatus('ok');

    return setStatus(null);
  }

  renderValidationIcon(error) {
    const { status } = this.props;
    if (status === 'ok') {
      return <Icon name="ok" className="text-success" />;
    }
    if (status === error) {
      return <Icon name="error" className="text-danger" />;
    }
    return undefined;
  }

  renderValidationMessage(error) {
    const { status } = this.props;
    if (status === error) {
      return (
        <small className="form-text text-muted">
          {t(['fields', 'password', error])}
        </small>
      );
    }

    return undefined;
  }

  render() {
    const { isRepeat, addInputRef, ...formEntryProps } = this.props;
    const id = isRepeat ? 'repeat' : 'password';
    const error = isRepeat ? 'identical' : 'length';

    return (
      <FormEntry id={id} {...formEntryProps}>
        <div className="input-group">
          <input
            type="password"
            className="form-control"
            id={id}
            onKeyUp={this.check}
            ref={addInputRef}
          />
          <div className="input-group-append">
            <div className="input-group-text">
              {this.renderValidationIcon(error)}
            </div>
          </div>
        </div>
        {this.renderValidationMessage(error)}
      </FormEntry>
    );
  }
}

export default Password;
