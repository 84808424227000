import React, { useState } from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../high-order/actions';

import Icon from '../elements/Icon';
import Navigation from '../elements/Navigation';

import OrganizationForm from '../display/organization/Form';
import MemberForm from '../display/organization/MemberForm';
import MemberList from '../display/organization/MemberList';
import PlanList from '../display/plan/List';

function AdminOrganization({
  actions,
  members = [],
  plans = [],
  organization = {},
}) {
  const [tab, setTab] = useState('members');
  const changeTab = (value) => {
    return (e) => {
      e.stopPropagation();
      e.preventDefault();
      setTab(value);
    };
  };

  const roles = members.reduce((collect, m) => {
    collect[m.role] = collect[m.role] || [];
    collect[m.role].push(m);
    return collect;
  }, {});

  const editMember = ({ uuid }) =>
    actions.navigation.open({
      section: 'admin',
      display: 'member',
      uuid,
    });

  return (
    <div className="organization">
      <div className="row">
        <div className="container">
          <h1 className="row">
            <span className="col-10">{organization.name}</span>
            <span className="title-icon col-2">
              <Icon name="organization" />
            </span>
          </h1>
          <OrganizationForm organization={organization} owner />
        </div>
      </div>
      <div className="row">
        <div className="container text-uppercase">
          <div className="row">
            <ul className="nav nav-tabs col-12">
              <li className="nav-item col-3">
                <a
                  href="#"
                  className={`nav-link ${tab === 'members' ? 'active' : ''}`}
                  onClick={changeTab('members')}
                >
                  {t('titles.list.members')}
                </a>
              </li>
              <li className="nav-item col-3">
                <a
                  href="#"
                  className={`nav-link ${tab === 'plan' ? 'active' : ''}`}
                  onClick={changeTab('plan')}
                >
                  {t('titles.list.plans')}
                </a>
              </li>
              <li className="col-6 text-primary text-right mt-2">
                <Icon name={tab} />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="row">
        {tab === 'members' && (
          <div className="container mt-5">
            <MemberForm organization={organization.uuid} />
            <MemberList
              role="admin"
              members={roles.admin}
              editCallback={editMember}
            />
            <MemberList
              role="manager"
              members={roles.manager}
              editCallback={editMember}
            />
            <MemberList
              role="member"
              members={roles.member}
              editCallback={editMember}
            />
            <MemberList
              role="locked"
              members={roles.locked}
              editCallback={editMember}
            />
          </div>
        )}
        {tab === 'plan' && (
          <div className="container">
            <div className="row mt-5 justify-content-end">
              <Navigation
                className="btn btn-primary"
                section="admin"
                display="plancreate"
                uuid={organization.uuid}
                metadata={{ mode: 'create' }}
              >
                {t('commons.add')} <Icon name="add" />
              </Navigation>
            </div>
            <PlanList
              plans={plans.filter(
                (plan) => plan.organization === organization.uuid
              )}
              admin
            />
          </div>
        )}
      </div>
    </div>
  );
}

export const adminorganization = dataWrapper(
  {
    members: ['data', 'members'],
    plans: ['data', 'plans'],
    organization: ['data', 'organization'],
  },
  actionsWrapper(['organization', 'navigation', 'toast'], AdminOrganization)
);

adminorganization.header = true;
adminorganization.footer = true;
