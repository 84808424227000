import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../high-order/actions';

import FormEntry from '../elements/FormEntry';
import Tags from '../elements/Tags';
import { Table, Column } from '../elements/table';
import { mailToPrinter, arrayPrinter } from '../elements/printers';

export const membersearch = dataWrapper(
  { members: ['data', 'profiles'] },
  actionsWrapper(['navigation'], ({ members = [], actions }) => (
    <div className="profile">
      <h2 className="col-12">{t('titles.search.profiles')}</h2>
      <FormEntry id="keywords">
        <Tags
          id="keywords"
          onChange={(target) => {
            actions.navigation.setMetadata({
              metadata: { skills: target.value },
            });
          }}
        />
      </FormEntry>
      <Table
        items={members
          .map((member) => ({
            fullname: `${member.firstname || ''} ${member.lastname || ''}`,
            ...member,
          }))
          .sort((a, b) => b.es_score - a.es_score)}
      >
        <Column itemKey="fullname" title={t('fields.name.input-label')} />
        <Column itemKey="email" printer={mailToPrinter()} />
        <Column itemKey="phone" />
        <Column
          itemKey="skills"
          title={t('skills.name')}
          printer={arrayPrinter()}
        />
      </Table>
    </div>
  ))
);

membersearch.header = true;
membersearch.footer = true;
