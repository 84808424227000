import React, { Component } from 'react';
import { map, transform } from 'lodash';
import { t } from 'zedevs-locales';

import { columnsToGrid, sizeToGrid } from '../../utils';

class NestedFlags extends Component {
  constructor() {
    super();

    this.inputs = {};

    this.setRef = this.setRef.bind(this);
  }

  get value() {
    return transform(
      this.inputs,
      (groupValues, groupInputs, groupId) => {
        groupValues[groupId] = transform(
          groupInputs,
          (values, input, id) => {
            values[id] = input.checked;
            return values;
          },
          {}
        );

        return groupValues;
      },
      {}
    );
  }

  get id() {
    const { id } = this.props;
    return id;
  }

  setRef(group, value, e) {
    this.inputs[group] = this.inputs[group] || {};
    this.inputs[group][value] = e;
  }

  render() {
    const { id, items, cols = [1], sizes = [12], inline } = this.props;
    const goupClassnames = sizeToGrid('col', false, ...columnsToGrid(...cols));

    const labelClassnames = [
      'col-form-label',
      ...sizeToGrid('col', true, ...sizes),
    ];
    const formControlClassNames = [
      'form-control',
      ...sizeToGrid('col', false, ...sizes),
    ];

    return (
      <div className="container">
        <div className="row">
          {map(items, ({ values, label: groupLabel }, groupId) => (
            <div className={goupClassnames.join(' ')} key={groupId}>
              <div className="row">
                <div className={labelClassnames.join(' ')}>
                  {groupLabel + t('commons.dots')}
                </div>
                <div className={formControlClassNames.join(' ')}>
                  {map(values, ({ checked, label: valueLabel }, valueId) => (
                    <div
                      className={`custom-control custom-checkbox ${
                        inline ? 'custom-control-inline' : ''
                      }`}
                      key={valueId}
                    >
                      <input
                        id={`${id}-${groupId}-${valueId}`}
                        className="custom-control-input"
                        type="checkbox"
                        defaultChecked={checked}
                        ref={(e) => this.setRef(groupId, valueId, e)}
                      />
                      <label
                        htmlFor={`${id}-${groupId}-${valueId}`}
                        className="custom-control-label"
                      >
                        {valueLabel}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default NestedFlags;
