/* eslint-disable class-methods-use-this */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isArray, pick } from 'lodash';

export function root(actions, hooks, Wrapped) {
  const name = Wrapped.name || Wrapped.displayName || 'Component';

  const ComposedComponent = class extends Component {
    // Handling child context
    getChildContext() {
      return {
        actions,
        hooks,
      };
    }

    // Render shim
    render() {
      return <Wrapped {...this.props} />;
    }
  };

  ComposedComponent.displayName = `ActionRoot${name}`;
  ComposedComponent.childContextTypes = {
    actions: PropTypes.object,
    hooks: PropTypes.object,
  };

  return ComposedComponent;
}

export function branch(_sections, Wrapped, useForward) {
  const sections = isArray(_sections) ? _sections : [_sections];
  const name = Wrapped.name || Wrapped.displayName || 'Component';

  class ComposedComponent extends Component {
    constructor() {
      super();

      this.displayName = `ActionBranch${name}`;
    }

    render() {
      const { actions, hooks } = this.context;
      const { forwardedRef, ...rest } = this.props;
      return (
        <Wrapped
          ref={forwardedRef}
          {...rest}
          actions={pick(actions, sections)}
          hooks={hooks}
        />
      );
    }
  }

  ComposedComponent.propTypes = {
    forwardedRef: PropTypes.any,
  };
  ComposedComponent.contextTypes = {
    actions: PropTypes.object,
    hooks: PropTypes.object,
  };

  const ForwardedComponent = React.forwardRef((props, ref) => {
    return <ComposedComponent {...props} forwardedRef={ref} />;
  });

  return useForward ? ForwardedComponent : ComposedComponent;
}
