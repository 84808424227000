import React from 'react';

export default function Logo() {
  return (
    <div className="logo logo-text">
      <span>Ze</span>
      <span>Devs</span>
    </div>
  );
}
