import React from 'react';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../high-order/actions';

import Icon from '../elements/Icon';

import Form from '../display/organization/Form';

export const organizationcreate = actionsWrapper(
  ['navigation', 'organization'],
  ({ actions }) => (
    <div className="organization">
      <h1 className="row">
        <span className="col-10">{t('titles.new.organization')}</span>
        <span className="title-icon col-2">
          <Icon name="organization" />
        </span>
      </h1>
      <Form
        callback={(err) => {
          actions.organization.list();
          if (!err) actions.navigation.close();
        }}
        owner
      />
    </div>
  )
);
