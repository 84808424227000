import React from 'react';
import { t } from 'zedevs-locales';

import { columnsToGrid, sizeToGrid } from '../../utils';

const FormEntry = ({
  id,
  label,
  children,
  cols = [1],
  sizes = [12],
  help,
  optional,
}) => {
  const goupClassnames = sizeToGrid('col', false, ...columnsToGrid(...cols));
  const labelClassnames = [
    'col-form-label',
    ...sizeToGrid('col', true, ...sizes),
  ];
  const inputClassnames = sizeToGrid('col', false, ...sizes);
  const helpClassnames = [
    'form-text',
    'text-muted',
    'text-right',
    ...sizeToGrid('offset', false, ...sizes.map((s) => 12 - s)),
    ...sizeToGrid('col', false, ...sizes),
  ];

  let labelElement;
  if (label === undefined) {
    labelElement = (
      <label htmlFor={id} className={labelClassnames.join(' ')}>
        {t(['fields', id, 'input-label']) + t('commons.dots')}
      </label>
    );
  } else if (label !== null) {
    labelElement = (
      <label htmlFor={id} className={labelClassnames.join(' ')}>
        {label + t('commons.dots')}
      </label>
    );
  }

  return (
    <div className={goupClassnames.join(' ')}>
      <div className={`form-group row ${optional ? 'optional' : ''}`}>
        {labelElement}
        <div className={inputClassnames.join(' ')}>{children}</div>
        {help ? (
          <small className={helpClassnames.join(' ')}>{help}</small>
        ) : undefined}
      </div>
    </div>
  );
};

export default FormEntry;
