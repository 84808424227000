import { omit } from 'lodash';
import React, { useState } from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../high-order/actions';

import Logo from '../elements/Logo';

import FormEntry from '../elements/FormEntry';
import Password from '../elements/Password';

function ProfileRegister({ actions, hooks, user = {} }) {
  const [passwordStatus, setPasswordStatus] = useState(null);

  const { onSubmit, addInputRef, submit, getData } = hooks.useForm({
    action: (data, callback) => {
      if (data.password === data.repeat) {
        actions.profile.register(
          { uuid: user.uuid, data: omit(data, 'repeat') },
          callback
        );
      }
    },
    button: {
      label: 'commons.save',
    },
  });

  return (
    <div className="register">
      <div className="mask">
        <div className="center">
          <Logo />
          <form
            className="form row justify-content-center"
            autoComplete="off"
            onSubmit={onSubmit}
          >
            <h4 className="title">{t('register.title')}</h4>
            <FormEntry id="email">
              <input
                type="email"
                className="form-control"
                id="email"
                defaultValue={user.email}
                disabled
              />
            </FormEntry>
            <FormEntry id="firstname">
              <input
                type="text"
                className="form-control"
                id="firstname"
                defaultValue={user.firstname}
                ref={addInputRef}
              />
            </FormEntry>
            <FormEntry id="lastname">
              <input
                type="text"
                className="form-control"
                id="lastname"
                defaultValue={user.lastname}
                ref={addInputRef}
              />
            </FormEntry>
            <FormEntry id="phone">
              <input
                type="phone"
                className="form-control"
                id="phone"
                defaultValue={user.phone}
                ref={addInputRef}
              />
            </FormEntry>
            <Password
              label={t('fields.password.input-label')}
              status={passwordStatus}
              setStatus={setPasswordStatus}
              getData={getData}
              addInputRef={addInputRef}
            />
            <Password
              label={t('register.password-repeat')}
              status={passwordStatus}
              setStatus={setPasswordStatus}
              isRepeat
              getData={getData}
              addInputRef={addInputRef}
            />
            <div className="form-group submit">{submit}</div>
          </form>
        </div>
      </div>
    </div>
  );
}

export const profileregister = dataWrapper(
  {
    locale: ['locale'],
    user: ['data', 'user'],
  },
  actionsWrapper(['profile'], ProfileRegister)
);
profileregister.header = false;
profileregister.footer = true;
