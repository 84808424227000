import React from 'react';

import Icon from '../Icon';

export const medalPrinter = () => {
  return function MedalPrinter({ item, itemKey }) {
    return item[itemKey] ? (
      <Icon name="certified" className="text-dark" />
    ) : null;
  };
};
