import React from 'react';
import { t, LocaleDate } from 'zedevs-locales';

import { branch as actionsWrapper } from '../../high-order/actions';

import Icon from '../../elements/Icon';
import { buttonGroupPrinter } from '../../elements/printers';
import { Table, Column } from '../../elements/table';
import Navigation from '../../elements/Navigation';

export default actionsWrapper(
  ['navigation', 'response', 'toast'],
  function ResponseView({
    actions,
    response = {},
    displaySlots,
    allowReserve,
  }) {
    const slots = (response.slots || []).map(({ start, stop }) => {
      return {
        start,
        display: [
          new LocaleDate(start).format('LLLL'),
          '-',
          new LocaleDate(stop).format('LT'),
        ].join(' '),
      };
    });

    function doReserve(start) {
      actions.response.reserve(
        { response: response.uuid, start },
        actions.toast.wrapper({
          error: 'intervention-create-error',
          success: 'intervention-create-success',
          callback: (err) => {
            if (!err) {
              actions.navigation.close();
              actions.navigation.reload();
            }
          },
        })
      );
    }

    return (
      <div className="response">
        <h1 className="row">
          <span className="col-10">{t('titles.view.response')}</span>
          <span className="title-icon col-2">
            <Icon name="response" />
          </span>
        </h1>
        <div className="row scrollable">
          <div className="container">
            <div className="row">
              <h2 className="col-12">{t('response.details')}</h2>
              <h3 className="col-12">{t('fields.description.input-label')}</h3>
              <div className="col-12">
                {(response.description || '').split('\n').map((text, index) => (
                  <p className="col-12 my-0" key={`description-${index}`}>
                    {text}
                  </p>
                ))}
              </div>
              <h3 className="col-12">
                {t('fields.prerequisites.input-label')}
              </h3>
              <div className="col-12">
                {(response.prerequisites || '')
                  .split('\n')
                  .map((text, index) => (
                    <p className="col-12 my-0" key={`prerequisites-${index}`}>
                      {text}
                    </p>
                  ))}
              </div>
              <h3 className="col-12">{t('fields.duration.input-label')}</h3>
              <div className="col-12">
                {response.duration}
                &nbsp;
                {t('commons.units.hour', response.duration)}
              </div>
              <h3 className="col-12">{t('fields.price.input-label')}</h3>
              <div className="col-12">{response.price}€ HT</div>
            </div>
            {displaySlots && (
              <div className="row">
                <h2 className="col-12">{t('titles.list.availabilities')}</h2>
                {(slots.length > 0 || allowReserve) && (
                  <Table items={slots} header={false}>
                    <Column itemKey="display" />
                    {allowReserve && (
                      <Column
                        title=""
                        printer={buttonGroupPrinter([
                          {
                            label: t('commons.reserve'),
                            color: 'primary',
                            callback: ({ start }) => doReserve(start),
                          },
                        ])}
                        align="center"
                      />
                    )}
                  </Table>
                )}
                {!slots.length && !allowReserve && (
                  <div className="col-12 text-center mb-3">
                    <p>{t('response.no-slot')}</p>
                    <Navigation
                      className="btn btn-primary"
                      section="my"
                      display="availabilities"
                    >
                      {t('titles.new.availability')}
                      &nbsp;
                      <Icon name="availability" />
                    </Navigation>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
);
