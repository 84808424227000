import React from 'react';
import { t } from 'zedevs-locales';

import { branch as actionsWrapper } from '../../high-order/actions';
import { commonSizes, isSIREN } from '../../../utils';

import FormEntry from '../../elements/FormEntry';
import NestedFlags from '../../elements/NestedFlags';

export default actionsWrapper(
  ['organization', 'navigation', 'toast'],
  function OrganizationForm({
    hooks,
    actions,
    organization = {},
    readOnly,
    owner,
    callback = () => {},
  }) {
    const mode = organization.uuid ? 'update' : 'create';

    const { onSubmit, addInputRef, submit } = hooks.useForm({
      action: (data, cb) => {
        if (data.siren && !isSIREN(data.siren)) {
          return actions.toast.warning(
            {
              message: t('toast.organization-siren-error', {
                value: data.siren,
              }),
            },
            (err, toast) => cb(err || toast)
          );
        }
        return actions.organization[mode](
          { uuid: organization.uuid, data: data },
          cb
        );
      },
      types: {
        name: 'string',
        siren: '?string',
        address: '?string',
        complement: '?string',
        postalCode: '?string',
        city: '?string',
        country: '?string',
        metadata: '?organizationMetadata',
      },
      error: `organization-${mode}-error`,
      success: `organization-${mode}-success`,
      callback,
      button: {
        label: 'commons.save',
      },
    });

    const features = (organization.metadata || {}).features || {};

    return (
      <form className="row" onSubmit={onSubmit}>
        <h2 className="col-12">{t('organization.details')}</h2>
        <FormEntry id="name" {...commonSizes.double}>
          <input
            type="text"
            className="form-control"
            id="name"
            key={organization.uuid}
            defaultValue={organization.name}
            readOnly={readOnly}
            ref={addInputRef}
          />
        </FormEntry>
        <FormEntry id="siren" {...commonSizes.double} optional>
          <input
            type="text"
            className="form-control"
            id="siren"
            key={organization.uuid}
            defaultValue={organization.siren}
            readOnly={readOnly}
            ref={addInputRef}
          />
        </FormEntry>
        <FormEntry id="address" {...commonSizes.double} optional>
          <input
            type="text"
            className="form-control"
            id="address"
            key={organization.uuid}
            defaultValue={organization.address}
            readOnly={readOnly}
            ref={addInputRef}
          />
        </FormEntry>
        <FormEntry id="complement" {...commonSizes.double} optional>
          <input
            type="text"
            className="form-control"
            id="complement"
            key={organization.uuid}
            defaultValue={organization.complement}
            readOnly={readOnly}
            ref={addInputRef}
          />
        </FormEntry>
        <FormEntry id="postalCode" {...commonSizes.triple} optional>
          <input
            type="text"
            className="form-control"
            id="postalCode"
            key={organization.uuid}
            defaultValue={organization.postalCode}
            readOnly={readOnly}
            ref={addInputRef}
          />
        </FormEntry>
        <FormEntry id="city" {...commonSizes.triple} optional>
          <input
            type="text"
            className="form-control"
            id="city"
            key={organization.uuid}
            defaultValue={organization.city}
            readOnly={readOnly}
            ref={addInputRef}
          />
        </FormEntry>
        <FormEntry id="country" {...commonSizes.triple} optional>
          <input
            type="text"
            className="form-control"
            id="country"
            key={organization.uuid}
            defaultValue={organization.country}
            readOnly={readOnly}
            ref={addInputRef}
          />
        </FormEntry>
        {owner && (
          <FormEntry id="metadata">
            <NestedFlags
              id="metadata"
              ref={addInputRef}
              {...commonSizes.simple}
              inline
              items={{
                features: {
                  label: t('organization.metadata.features.label'),
                  values: {
                    publish: {
                      label: t('organization.metadata.features.publish'),
                      checked: features.publish,
                    },
                    reply: {
                      label: t('organization.metadata.features.reply'),
                      checked: features.reply,
                    },
                  },
                },
              }}
            />
          </FormEntry>
        )}
        {readOnly ? undefined : (
          <div className="form-group submit col-12">{submit}</div>
        )}
      </form>
    );
  }
);
