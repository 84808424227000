import { LocaleDate, t } from 'zedevs-locales';
import { serviceToStateCallbackFactory } from '../utils';

export default {
  get({ state, clients, callback }, { uuid }) {
    const profile = uuid;

    clients.api.profile.get(
      { params: { profile } },
      serviceToStateCallbackFactory(state, ['data', 'profile'], callback)
    );
  },
  update({ state, clients, callback }, { uuid, data }) {
    const profile = uuid || state.get(['data', 'user', 'uuid']);

    clients.api.profile.update(
      { params: { profile }, data },
      serviceToStateCallbackFactory(
        state,
        ['data', uuid ? 'profile' : 'user'],
        callback
      )
    );
  },
  getSkills({ state, clients, callback }, { uuid }) {
    const profile = uuid || state.get(['data', 'user', 'uuid']);

    clients.api.profile.getSkills(
      { params: { profile } },
      serviceToStateCallbackFactory(state, ['data', 'skills'], callback)
    );
  },
  addSkill({ state, clients, callback }, { data, profile }) {
    clients.api.profile.addSkill(
      {
        params: { profile: profile || state.get(['data', 'user', 'uuid']) },
        data,
      },
      callback
    );
  },
  updateSkill({ state, clients, callback }, { uuid, data, profile }) {
    clients.api.profile.updateSkill(
      {
        params: {
          profile: profile || state.get(['data', 'user', 'uuid']),
          uuid,
        },
        data,
      },
      callback
    );
  },
  certifySkill({ clients, callback }, { uuid, profile }) {
    clients.api.profile.certifySkill(
      { params: { profile, uuid }, data: { certified: true } },
      callback
    );
  },
  uncertifySkill({ clients, callback }, { uuid, profile }) {
    clients.api.profile.certifySkill(
      { params: { profile, uuid }, data: { certified: false } },
      callback
    );
  },
  deleteSkill({ state, clients, callback }, { uuid, profile }) {
    clients.api.profile.deleteSkill(
      {
        params: {
          profile: profile || state.get(['data', 'user', 'uuid']),
          uuid,
        },
      },
      callback
    );
  },
  getRegistration({ state, clients, callback }, { uuid }) {
    clients.api.profile.getRegistration(
      { params: { profile: uuid } },
      serviceToStateCallbackFactory(state, ['data', 'user'], callback)
    );
  },

  reinvite({ clients, callback }, { uuid }) {
    clients.api.profile.reinvite({ params: { profile: uuid } }, callback);
  },
  register({ state, clients, actions, callback }, { uuid, data }) {
    clients.api.profile.setRegistration(
      { params: { profile: uuid }, data },
      (err, res) => {
        if (err) return callback(err);
        state.set(['data', 'user'], null);
        actions.navigation.open();
        return callback(null, res);
      }
    );
  },
  getAssists({ state, clients, callback }) {
    const profile = state.get(['data', 'user', 'uuid']);
    clients.api.profile.getAssists(
      { params: { profile } },
      serviceToStateCallbackFactory(state, ['data', 'assists'], callback)
    );
  },
  getResponses({ state, clients, callback }) {
    const profile = state.get(['data', 'user', 'uuid']);
    clients.api.profile.getResponses(
      { params: { profile } },
      serviceToStateCallbackFactory(state, ['data', 'responses'], callback)
    );
  },
  getInterventions({ state, clients, callback }) {
    const profile = state.get(['data', 'user', 'uuid']);

    const { view = 'week', date = Date.now() } = state.get([
      'navigation',
      'page',
      'metadata',
    ]);

    const start = new LocaleDate(date).startOf(view).getTime();
    const end = new LocaleDate(date).endOf(view).getTime();

    clients.api.profile.getInterventions(
      { params: { profile }, data: { start, end } },
      serviceToStateCallbackFactory(state, ['data', 'interventions'], callback)
    );
  },
  setAvailabilities({ state, clients, callback }, { data }) {
    const profile = state.get(['data', 'user', 'uuid']);

    clients.api.profile.setAvailabilities(
      { params: { profile }, data },
      (err, res) => {
        if (err) return callback(err);
        return callback(null, res);
      }
    );
  },
  getAvailabilities({ state, clients, callback }) {
    const profile = state.get(['data', 'user', 'uuid']);

    clients.api.profile.getAvailabilities(
      { params: { profile } },
      serviceToStateCallbackFactory(state, ['data', 'availabilities'], callback)
    );
  },

  forgotPassword({ clients, callback }, { email }) {
    clients.api.password.createToken({ data: { email } }, callback);
  },
  checkPasswordToken({ clients, actions, callback }, { uuid }) {
    clients.api.password.checkToken({ params: { token: uuid } }, (err) => {
      if (err) {
        actions.toast.error({ message: t('toast.reset-password-expired') });
        actions.navigation.open();
      }
      callback();
    });
  },
  resetPassword({ clients, callback }, { token, password }) {
    clients.api.password.useToken(
      { params: { token }, data: { password } },
      callback
    );
  },
  search({ state, clients, callback }) {
    const skills = state.get(['navigation', 'page', 'metadata', 'skills']);
    clients.api.profile.search({ data: { skills } }, (err, res) => {
      if (err) {
        state.set(['data', 'profiles'], []);
        return callback(err);
      }
      state.set(['data', 'profiles'], res);
      return callback(null, res);
    });
  },
  getPlans({ state, clients, callback }) {
    const profile = state.get(['data', 'user', 'uuid']);

    clients.api.profile.getPlans(
      { params: { profile } },
      serviceToStateCallbackFactory(state, ['data', 'plans'], callback)
    );
  },
};
