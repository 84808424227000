import { serviceToStateCallbackFactory } from '../utils';

export default {
  get({ state, clients, callback }, { uuid }) {
    clients.api.response.get(
      { params: { response: uuid } },
      serviceToStateCallbackFactory(state, ['data', 'response'], callback)
    );
  },
  reserve({ clients, callback }, { response, start }) {
    clients.api.response.reserve(
      { params: { response }, data: { start } },
      callback
    );
  },
};
