import { serviceToStateCallbackFactory } from '../utils';

export default {
  create({ state, clients, callback }, { data: { plan, ...data } }) {
    clients.api.plan.createProvider(
      { params: { plan }, data },
      serviceToStateCallbackFactory(state, ['data', 'provider'], callback)
    );
  },
  get({ state, clients, callback }, { uuid }) {
    clients.api.provider.get(
      { params: { provider: uuid } },
      serviceToStateCallbackFactory(state, ['data', 'provider'], callback)
    );
  },
  getCandidates({ state, clients, callback }, { uuid }) {
    clients.api.provider.getCandidates(
      { params: { provider: uuid } },
      serviceToStateCallbackFactory(
        state,
        ['data', 'expertCandidates'],
        callback
      )
    );
  },
  addExpert({ clients, callback }, { provider, expert }) {
    clients.api.provider.addExpert(
      { params: { provider }, data: { expert } },
      callback
    );
  },
  removeExpert({ clients, callback }, { provider, expert }) {
    clients.api.provider.removeExpert(
      { params: { provider, expert } },
      callback
    );
  },
  update({ state, clients, callback }, { uuid, data }) {
    clients.api.provider.update(
      { params: { provider: uuid }, data },
      serviceToStateCallbackFactory(state, ['data', 'provider'], callback)
    );
  },
};
