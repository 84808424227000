import React from 'react';
import { branch as dataWrapper } from 'baobab-react/higher-order';

import View from '../display/intervention/View';

function InterventionView({ assist = {}, response = {}, intervention = {} }) {
  return (
    <div className="assist">
      <View
        intervention={intervention}
        response={response}
        assist={assist}
        mainPage
      />
    </div>
  );
}

export const interventionview = dataWrapper(
  {
    intervention: ['data', 'intervention'],
    response: ['data', 'response'],
    assist: ['data', 'assist'],
  },
  InterventionView
);
interventionview.header = true;
interventionview.footer = true;
